import * as TYPES from "./types";
import api from "../api/api";
import history from "../history";
// React Notification
import { NotificationManager } from "react-notifications";

// USER MANAGEMENT

//login

export const signIn = (formValues) => async (dispatch) => {
  const data = { empCode: formValues.empCode, pswd: formValues.password };
  // console.log(data);
  try {
    const res = await api.post("/login", data);
    dispatch({ type: TYPES.SIGN_IN, payload: res.data });
    // console.log(res.data);
    if (res.data.token) {
      localStorage.setItem(
        "data",
        JSON.stringify({
          token: res.data.token,
          // name: res.data.user.name,
          // id: res.data.user._id,
        })
      );
      history.push("/dashboard");
      window.location.reload(true);
    }
  } catch (e) {
    console.log(e);
  }
};

//fetch User details

export const fetchUserDetails = () => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: `/employee/getDetails`,
      headers: { Authorization: `Bearer ${data.token}` },
    });
    // console.log(res.data);

    dispatch({ type: TYPES.FETCH_USER_DETAIL, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

//fetch user

export const fetchUser = () => {
  const data = JSON.parse(localStorage.getItem("data"));
  if (!data) {
    return { type: TYPES.FETCH_USER };
  }
  return { type: TYPES.FETCH_USER, payload: data };
};

//signout

export const signOut = () => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/employee/logout",
      headers: { Authorization: `Bearer ${data.token}` },
    });
    console.log(res);
    localStorage.clear();
    history.push("/");
    window.location.reload(true);
  } catch (e) {
    console.log(e);
  }
  dispatch({ type: TYPES.SIGN_OUT });
};

//CLIENT MANAGEMENT

//show all client list
export const getAllClients = (number) => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/fetchClients",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { offset: number },
    });
    // console.log(res.data);
    dispatch({ type: TYPES.FETCH_ALL_CLIENTS, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

//Search Clients
export const getClientsBySearchValue = (searchTerm) => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/client/search",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { search: searchTerm },
    });
    console.log(res.data);
    dispatch({ type: TYPES.FETCH_CLIENTS_BY_SEARCH, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

//Fetch Clients by CLID
export const fetchClientByClid = (clid) => async (dispatch) => {
  // console.log(clid);
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: `/client/fetchByClid`,
      headers: { Authorization: `Bearer ${data.token}` },
      data: { clid: clid },
    });
    // console.log(res.data);

    dispatch({ type: TYPES.FETCH_CLIENT_BY_CLID, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

//Add Clients
export const addClient = (formValues) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("data")).token;
  const data = {
    accMgr: formValues.accMgr,
    address: formValues.address + ", " + formValues.address2,
    city: formValues.city,
    gst: formValues.gst.toUpperCase(),
    email: formValues.email,
    number: formValues.number,
    clientRole: formValues.clientType,
    companyName: formValues.companyName,
    contactPerson: formValues.contactPerson,
    country: formValues.country,
    pinCode: formValues.pinCode,
    salesPerson: formValues.salesPerson,
    state: formValues.state,
    isReviewed: "false",
  };
  console.log(data);
  try {
    await api({
      method: "post",
      url: "/client/add",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    });
    //dispatch({ type: TYPES.SIGN_IN, payload: res.data });
    // console.log(res.data);
    // if (res.data.token) {

    // }
  } catch (e) {
    console.log(e);
  }
};

//Update Clients
export const updateClient = (formValues, clid) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("data")).token;
  // console.log(formValues, clid);
  const data = {
    accMgr: formValues.accMgr,
    address: formValues.address + ", " + formValues.address2,
    city: formValues.city,
    gst: formValues.gst.toUpperCase(),
    email: formValues.email,
    number: formValues.number,
    clientRole: formValues.clientType,
    companyName: formValues.companyName,
    contactPerson: formValues.contactPerson,
    country: formValues.country,
    pinCode: formValues.pinCode,
    salesPerson: formValues.salesPerson,
    state: formValues.state,
    clid: clid,
  };
  // console.log(data);
  try {
   await api({
      method: "post",
      url: "/client/edit",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    });
    //dispatch({ type: TYPES.SIGN_IN, payload: res.data });
    // console.log(res.data);
    
  } catch (e) {
    console.log(e);
  }
};

//EMPLOYEE MANAGEMENT

//show all employee

export const getAllEmployeeName = () => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/employees",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { offset: 0 },
    });
    // console.log(res.data);
    dispatch({ type: TYPES.FETCH_ALL_EMPLOYEE_NAME, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

//Fetch All Employee and from search
export const fetchAllEmployee = (offsetValue, searchTerm) => async (
  dispatch
) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "get",
      url: `/employee/all?offset=${offsetValue}&search=${searchTerm}&fetchReportData=true`,
      headers: { Authorization: `Bearer ${data.token}` },
    });
    // console.log(res.data);

    dispatch({ type: TYPES.FETCH_ALL_EMPLOYEE, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

//fetch notification

export const getAllNotification = () => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/notifications",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { offset: 0 },
    });
    // console.log(res.data);
    dispatch({ type: TYPES.FETCH_NOTIFICATION, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

//VENDOR MANAGEMENT

//show all vendor

export const getAllVendor = () => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/vendor",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { offset: 0 },
    });
    // console.log(res.data);
    dispatch({ type: TYPES.FETCH_ALL_VENDOR, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

//DEVICE MANAGEMENT

//show all Device

export const getAllDevice = (pageNumber) => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/device/fetchAllDevices",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { offset: pageNumber },
    });
    // console.log(res.data);
    dispatch({ type: TYPES.FETCH_ALL_DEVICE, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

//Search device by search value

export const getDeviceBySearchValue = (searchTerm) => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/device/search",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { imei: searchTerm },
    });
    // console.log(res.data);
    dispatch({ type: TYPES.FETCH_DEVICE_BY_SEARCH, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

//show all Device Model Name
export const getAllDeviceModelName = () => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/device/fetchAllDeviceNames",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { offset: 0 },
    });
    // console.log(res.data);
    dispatch({ type: TYPES.FETCH_ALL_DEVICE_MODEL_NAME, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

// add devices
export const addDevice = (deviceData) => async (dispatch) => {
  console.log(deviceData);
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/device/addDevices",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { devices: deviceData },
      timeout: 600000,
    });
    // console.log(res.data);

    // const message = () =>
    //   res.data.notInsertedImeis === []
    //     ? "Added Successfully"
    //     : `Not inserted Devices: ${res.data.notInsertedImeis}`;
    res.data.message &&
      NotificationManager.success("Successfullly Added", "Successful!", 2000);
    // dispatch({ type: TYPES.FETCH_ALL_DEVICE, payload: res.data });
  } catch (e) {
    console.log(e);
    NotificationManager.error("Error Occured", "error!", 2000);
  }
};

// update devices

export const updateDevice = (
  imeiArray,
  orderId,
  location,
  locationName
) => async (dispatch) => {
  // console.log(imeiArray, orderId, location, locationName);
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/device/updateDevices",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { imeiArray, orderId, location, locationName },
      timeout: 600000,
    });
    // console.log(res.data);
    // dispatch({ type: TYPES.FETCH_ALL_DEVICE, payload: res.data });
    res.data.message &&
      NotificationManager.success(
        "Successfullly Assigned",
        "Successful!",
        2000
      );
  } catch (e) {
    console.log(e);
    NotificationManager.error(e, "error!", 2000);
  }
};

//check devices in database

export const checkIMEIExistence = (deviceArray) => async (dispatch) => {
  // console.log(deviceArray);
  const data = JSON.parse(localStorage.getItem("data"));
  try {
     await api({
      method: "post",
      url: "/device/checkExistence",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { imeis: deviceArray },
      // timeout: 600000,
    });
    // console.log(res.data);
    // dispatch({ type: TYPES.FETCH_ALL_DEVICE, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

// SIM MANAGEMENT

//show all sims
export const getAllSim = (pageNumber) => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/sim/all",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { offset: pageNumber, count: 10 },
    });
    // console.log(res.data);
    dispatch({ type: TYPES.FETCH_ALL_SIM, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

//Search sim by search value

export const getSimBySearchValue = (searchTerm) => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/sim/search",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { number: searchTerm },
    });
    // console.log(res.data);
    dispatch({ type: TYPES.FETCH_SIM_BY_SEARCH, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

// add sims
export const addSim = (simData) => async (dispatch) => {
  // console.log(simData);
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/sim/addSims",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { sim: simData },
      timeout: 600000,
    });
    // console.log(res.data);

    // const message = () =>
    //   res.data.notInsertedSimNo === []
    //     ? "Added Successfully"
    //     : `Not inserted Devices: ${res.data.notInsertedSimNo}`;
    res.data.message &&
      NotificationManager.success("Successfullly Added", "Successful!", 2000);
    // dispatch({ type: TYPES.FETCH_ALL_DEVICE, payload: res.data });
  } catch (e) {
    console.log(e);
    NotificationManager.error("Error Occured", "error!", 2000);
  }
};

// update Sim

export const updateSim = (
  mobileNoArray,
  orderId,
  location,
  locationName
) => async (dispatch) => {
  // console.log(mobileNoArray, orderId, location, locationName);
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/sim/updateSim",
      headers: { Authorization: `Bearer ${data.token}` },
      data: { mobileNoArray, orderId, location, locationName },
      timeout: 600000,
    });
    // console.log(res.data);
    // dispatch({ type: TYPES.FETCH_ALL_DEVICE, payload: res.data });
    res.data.message &&
      NotificationManager.success(
        "Successfullly Assigned",
        "Successful!",
        2000
      );
  } catch (e) {
    console.log(e);
    NotificationManager.error(e, "error!", 2000);
  }
};

//ORDER MANAGEMENT

//show all Order list
export const getAllOrders = (
  number,
  accMgr,
  orderType,
  searchedName,
  startDate,
  endDate,
  clid
) => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  // console.log(
  //   number,
  //   accMgr,
  //   orderType,
  //   searchedName,
  //   startDate,
  //   endDate,
  //   clid
  // );

  const orderTypeCode = () => {
    switch (orderType) {
      case "New Order":
        return "1";
      case "Due Payment":
        return "2";
      case "Ready To Dispatch":
        return "3";
      case "Dispatched":
        return "7";
      default:
        return "6";
    }
  };
  try {
    const res = await api({
      method: "post",
      url: "/fetchCouriers",
      headers: { Authorization: `Bearer ${data.token}` },
      data: {
        offset: number,
        startDate: startDate || "",
        endDate: endDate || "",
        accMgr: accMgr ? [accMgr] : "",
        orderType: orderTypeCode(),
        searchedName: searchedName || "",
        clid: clid || "",
        fetchReportData:"true"
      },
    });
    // console.log(res.data);
    dispatch({ type: TYPES.FETCH_ALL_ORDERS, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

//Fetch Order Item by CdID
export const fetchOrderItemByCdid = (cdid) => async (dispatch) => {
  // console.log(cdid);
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: `/order/items`,
      headers: { Authorization: `Bearer ${data.token}` },
      data: { cdid: cdid },
    });
    // console.log(res.data);

    dispatch({
      type: TYPES.FETCH_ORDER_ITEMS_BY_CDID,
      payload: { data: res.data, cdid },
    });
  } catch (error) {
    console.log(error);
  }
};

//Fetch Order by CdID
export const fetchOrderByCdid = (cdid) => async (dispatch) => {
  // console.log(cdid);
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: `/fetchCourier`,
      headers: { Authorization: `Bearer ${data.token}` },
      data: { cdid: cdid },
    });
    // console.log(res.data);

    dispatch({
      type: TYPES.FETCH_ORDER_CDID,
      payload: { data: res.data.couriers[0] },
    });
  } catch (error) {
    console.log(error);
  }
};

// fetch all Order numbers

export const fetchAllOrderNumber = () => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "get",
      url: `/order/names`,
      headers: { Authorization: `Bearer ${data.token}` },
    });
    // console.log(res.data);

    dispatch({ type: TYPES.FETCH_ALL_ORDER_NUMBER, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

//PushBack or Reverse payment or update status
export const updateOrderStatus = (note, cdid, cmd) => async (dispatch) => {
  // console.log(note, cdid, cmd);
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: `/order/updateStatus`,
      headers: { Authorization: `Bearer ${data.token}` },
      data: { note, id: cdid, cmd },
    });
    // console.log(res.data);
    if (res.data.status === 1) {
      window.location.reload(true);
    }
  } catch (error) {
    console.log(error);
  }
};

//add notes in Order
export const addOrderNotes = (note, cdid) => async (dispatch) => {
  // console.log(note, cdid);
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: `/order/notes/add`,
      headers: { Authorization: `Bearer ${data.token}` },
      data: { note: note, id: cdid },
    });
    // console.log(res.data);

    dispatch({
      type: TYPES.ADD_ORDER_NOTE,
      payload: { data: res.data.note, cdid },
    });
  } catch (error) {
    console.log(error);
  }
};

//dispatch Courier
export const dispatchCourier = (
  note,
  id,
  address,
  type,
  vendorName,
  companyName,
  trackingNo,
  courierCharges
) => async (dispatch) => {
  // console.log(
  //   note,
  //   id,
  //   address,
  //   type,
  //   vendorName,
  //   companyName,
  //   trackingNo,
  //   courierCharges
  // );
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: `/order/dispatch`,
      headers: { Authorization: `Bearer ${data.token}` },
      data: {
        note,
        id,
        address,
        type,
        vendorName,
        companyName,
        trackingNo,
        courierCharges,
      },
    });
    // console.log(res.data);
    if (res.status === 200) {
      NotificationManager.success("Successfully Updated", "Successful!", 2000);
      window.location.reload(true);
    }
    // dispatch({
    //   type: TYPES.ADD_ORDER_NOTE,
    //   payload: { data: res.data.note, cdid },
    // });
  } catch (error) {
    console.log(error);
    NotificationManager.error("Error Occured", "error!", 2000);
  }
};

//confirm Courier
export const confirmCourier = (
  follow_up_date,
  note,
  cdid,
  payment_mode,
  received_amt,
  acc_no,
  chq_date,
  bank_name,
  discount,
  chq_no,
  paytm_ref_no,
  due_amt,
  chq_status,
  chq_posted_to
) => async (dispatch) => {
  // console.log(
  //   follow_up_date,
  //   note,
  //   cdid,
  //   payment_mode,
  //   received_amt,
  //   acc_no,
  //   chq_date,
  //   bank_name,
  //   discount,
  //   chq_no,
  //   paytm_ref_no,
  //   due_amt,
  //   chq_status,
  //   chq_posted_to
  // );
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: `/order/dispatch`,
      headers: { Authorization: `Bearer ${data.token}` },
      data: {
        follow_up_date,
        note,
        cdid,
        payment_mode,
        received_amt,
        acc_no,
        chq_date,
        bank_name,
        discount,
        chq_no,
        paytm_ref_no,
        due_amt,
        chq_status,
        chq_posted_to,
      },
    });
    // console.log(res.data);
    if (res.data.status === 1) {
      NotificationManager.success("Successfully Updated", "Successful!", 2000);
      window.location.reload(true);
    }
    // dispatch({
    //   type: TYPES.ADD_ORDER_NOTE,
    //   payload: { data: res.data.note, cdid },
    // });
  } catch (error) {
    console.log(error);
    NotificationManager.error("Error Occured", "error!", 2000);
  }
};

// BATCH MANAGEMENT

// fetch all batch numbers

export const fetchAllBatchNumber = () => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "get",
      url: `/batch/names`,
      headers: { Authorization: `Bearer ${data.token}` },
    });
    // console.log(res.data);

    dispatch({ type: TYPES.FETCH_ALL_BATCH_NUMBER, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

//LEADS MANAGEMENT

//Featch all Leads

export const getAllLeads = (offsetValue) => async (dispatch) => {
  const data = JSON.parse(localStorage.getItem("data"));
  // console.log(offsetValue);

  try {
    const res = await api({
      method: "get",
      url: `/crm/fetch?offset=${offsetValue}`,
      headers: { Authorization: `Bearer ${data.token}` },
    });
    // console.log(res.data);
    dispatch({ type: TYPES.FETCH_ALL_LEADS, payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

//add Leads
export const addLead = ({
  companyName,
  contactPerson,
  address,
  city,
  state,
  number,
  email,
  pinCode,
  remarks,
  status,
  followup,
}) => async (dispatch) => {
  // console.log(
  //   companyName,
  //   contactPerson,
  //   address,
  //   city,
  //   state,
  //   number,
  //   email,
  //   pinCode,
  //   remarks,
  //   status,
  //   followup
  // );
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/crm/add",
      headers: { Authorization: `Bearer ${data.token}` },
      data: {
        companyName,
        contactPerson,
        address,
        city,
        state,
        number,
        email,
        pinCode,
        remarks,
        status,
        followup,
      },
      timeout: 600000,
    });
    // console.log(res.data);
    res.data.status === 1
      ? NotificationManager.success("Successfullly Added", "Successful!", 2000)
      : NotificationManager.warning(res.data.error, "Warning!", 2000);

    // dispatch({ type: TYPES.FETCH_ALL_DEVICE, payload: res.data });
  } catch (e) {
    console.log(e);
    NotificationManager.error("Error Occured", "error!", 2000);
  }
};

//update Leads
export const updateLead = ({
  companyName,
  contactPerson,
  address,
  city,
  state,
  number,
  email,
  pinCode,
  remarks,
  status,
  followup,
  id,
}) => async (dispatch) => {
  // console.log(
  //   companyName,
  //   contactPerson,
  //   address,
  //   city,
  //   state,
  //   number,
  //   email,
  //   pinCode,
  //   remarks,
  //   status,
  //   followup,
  //   id
  // );
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: "/crm/edit",
      headers: { Authorization: `Bearer ${data.token}` },
      data: {
        companyName,
        contactPerson,
        address,
        city,
        state,
        number,
        email,
        pinCode,
        remarks,
        status,
        followup,
        id,
      },
      timeout: 600000,
    });
    // console.log(res.data);
    res.data.status === 1
      ? NotificationManager.success("Successfullly Added", "Successful!", 2000)
      : NotificationManager.warning(res.data.error, "Warning!", 2000);

    // dispatch({ type: TYPES.FETCH_ALL_DEVICE, payload: res.data });
  } catch (e) {
    console.log(e);
    NotificationManager.error("Error Occured", "error!", 2000);
  }
};

//add notes in Leads
export const addLeadsNotes = (note, cdid) => async (dispatch) => {
  // console.log(note, cdid);
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: `/crm/notes/add`,
      headers: { Authorization: `Bearer ${data.token}` },
      data: { note: note, id: cdid },
    });
    // console.log(res.data);

    dispatch({
      type: TYPES.ADD_LEAD_NOTE,
      payload: { data: res.data.note, cdid },
    });
  } catch (error) {
    console.log(error);
  }
};

//Fetch notes in Leads
export const fetchLeadsNotes = (id) => async (dispatch) => {
  // console.log(id);
  const data = JSON.parse(localStorage.getItem("data"));
  try {
    const res = await api({
      method: "post",
      url: `/crm/notes`,
      headers: { Authorization: `Bearer ${data.token}` },
      data: { id },
    });
    // console.log(res.data);

    dispatch({
      type: TYPES.FETCH_LEAD_NOTE,
      payload: { data: res.data.note, id },
    });
  } catch (error) {
    console.log(error);
  }
};
