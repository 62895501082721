import * as TYPES from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.FETCH_ALL_CLIENTS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        clients: action.payload.clients,
        numClients: action.payload.total,
        report: action.payload.report,
      };
    case TYPES.FETCH_CLIENTS_BY_SEARCH:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        clients: action.payload.clients,
      };
    case TYPES.FETCH_CLIENT_BY_CLID:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        [action.payload.clients[0].CLID]: action.payload.clients[0],
      };
    default:
      return state;
  }
};
