import * as TYPES from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.FETCH_ALL_VENDOR:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        vendors: action.payload.list,
      };
    default:
      return state;
  }
};
