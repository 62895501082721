import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

class DispatchForm extends Component {
  state = { type: null };
  courierVendors = [
    "M R Enterprises(Sardar Ji)",
    "Shri Ganesh International(Trackon)",
  ];

  courierCompanyNames = [
    "Shree Anjani Couriers",
    "Shree Tirupati Couriers",
    "Jetline Couriers",
    "Blue Dart",
    "First Flight",
    "FedEx",
    "DTDC",
    "TNT Express",
    "DTDC Lite",
    "Overnite Express",
    "The Professional Courier",
    "Trackon Courier",
    "Shree Maruti Courier",
    "Shree Nandan Courier",
    "ST Courier",
    "Delhivery",
    "Post"
  ];

  //convert select to redux field
  renderSelectTag = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <select
          className="form-control"
          type={formProps.type}
          {...formProps.input}
        >
          {formProps.children}
        </select>
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };

  //Render Options in select tag
  renderOptionList = (array) => {
    return array.map((el) => {
      return (
        <option key={array.indexOf(el) + 1} value={el}>
          {el}
        </option>
      );
    });
  };

  //convert input to redux field
  renderInput = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <input
          className="form-control"
          type={formProps.type}
          placeholder={formProps.placeholder}
          {...formProps.input}
        />
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };

  //convert textarea to redux field
  renderTextArea = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <textarea
          className="form-control"
          type={formProps.type}
          placeholder={formProps.placeholder}
          {...formProps.input}
        />
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };

  //Render Error Message
  renderErrorMessage = (meta) => {
    if (meta.touched) {
      return <div className="form__error">{meta.error}</div>;
    } else {
      return null;
    }
  };

  render() {
    return (
      <form>
        <div className="form-group" style={{ textAlign: "center" }}>
          <label className="control-label mb-10">Dispatch By</label>
          <div className="radio-list">
            <div className="radio-inline pl-0">
              <div className="radio radio-info">
                <Field
                  type="radio"
                  id="courier"
                  component="input"
                  name="dispatchType"
                  value="By Courier"
                  onClick={() => {
                    this.setState({ type: "courier" });
                  }}
                />
                <label htmlFor="courier">Courier</label>
              </div>
            </div>
            <div className="radio-inline">
              <div className="radio radio-info">
                <Field
                  type="radio"
                  id="byHand"
                  component="input"
                  name="dispatchType"
                  value="by hand"
                  onClick={() => {
                    this.setState({ type: "by hand" });
                  }}
                />
                <label htmlFor="byHand">By Hand</label>
              </div>
            </div>
          </div>
        </div>
        {this.state.type === "courier" && (
          <div>
            {" "}
            <div className="form-group">
              <label htmlFor="message-text" className="control-label mb-10">
                Address:
              </label>
              <Field
                type="textarea"
                name="address"
                component={this.renderTextArea}
                className="form-control"
                placeholder="Dispatched Address"
              />
            </div>
            <div className="form-group">
              <label className="control-label mb-10">Courier Vendors</label>
              <Field
                type="select"
                component={this.renderSelectTag}
                className="form-control"
                name="courierVendor"
              >
                <option values="">Select Courier Vendor</option>
                {this.renderOptionList(this.courierVendors)}
              </Field>
            </div>
            <div className="form-group">
              <label className="control-label mb-10">Courier Company</label>
              <Field
                type="select"
                component={this.renderSelectTag}
                className="form-control"
                name="courierCompanyName"
              >
                <option values="">Select Courier Vendor</option>
                {this.renderOptionList(this.courierCompanyNames)}
              </Field>
            </div>
            <div className="form-group">
              <label className="control-label mb-10">Tracking No</label>
              <Field
                type="text"
                component={this.renderInput}
                name="trackingNum"
                className="form-control"
                placeholder="AWB No or Reference no"
              />
            </div>
            <div className="form-group">
              <label className="control-label mb-10">Courier Charges</label>
              <div className="input-group">
                <div className="input-group-addon">
                  <i className="ti-money" />
                </div>
                <Field
                  type="text"
                  component={this.renderInput}
                  className="form-control"
                  name="courierCharges"
                  placeholder={153}
                />
              </div>
            </div>
          </div>
        )}
        <div className="form-group">
          <label htmlFor="message-text" className="control-label mb-10">
            Notes:
          </label>
          <Field
            type="textarea"
            name="notes"
            component={this.renderTextArea}
            className="form-control"
            placeholder="Any Other Details"
          />
        </div>
      </form>
    );
  }
}

//Client Side Form validation
const validate = (formValues) => {
  const errors = {};
  // var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!formValues.username) {
    errors.username = "You must enter your username";
  } else if (formValues.username.length < 5) {
    errors.username = "Employee Id must be atleast 7 long";
  }
  if (!formValues.pswd) {
    errors.pswd = "You must enter the password";
  } else if (formValues.pswd.length < 6) {
    errors.pswd = "Password must be atleast 6 long charector";
  }
  return errors;
};

//Connect with redux form with local redux store
export default connect(
  null,
  {}
)(
  reduxForm({
    form: "dispatchForm",
    validate,
  })(DispatchForm)
);
