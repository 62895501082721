import * as TYPES from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.FETCH_ALL_ORDERS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        orders: {
          ...action.payload.couriers.reduce((newState, courier) => {
            newState[courier.CDID] = courier;
            return newState;
          }, {}),
        },
        report: action.payload.report,
      };
    case TYPES.FETCH_ORDER_BY_SEARCH:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        orders: action.payload.couriers,
      };
    case TYPES.FETCH_ORDER_ITEMS_BY_CDID:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        [action.payload.cdid]: action.payload.data.products,
      };
    case TYPES.FETCH_ORDER_CDID:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        currentOrder: action.payload.data,
      };
    case TYPES.ADD_ORDER_NOTE:
      if (!action.payload) {
        return state;
      }
      const newState = state.orders[action.payload.cdid].Notes;
      newState.unshift(action.payload.data);
      console.log(newState);
      return {
        ...state,
        newState,
      };
    default:
      return state;
  }
};
