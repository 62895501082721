import React, { Component } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import history from "./history";
import Login from "../src/Component/Login";
import Dashboard from "../src/Component/Dashboard";
import Clients from "../src/Component/Client/Clients";
import ClientEdit from "../src/Component/Client/ClientEdit";
import Sidebar from "../src/Component/Fixed/Sidebar";
import Topbar from "../src/Component/Fixed/Topbar";
import RightSideSettingMenu from "../src/Component/Fixed/RightSideSettingMenu";
import RightSidebar from "./Component/Fixed/RightSidebar";
import Orders from "./Component/Order/Orders";
import Employees from "./Component/Employee/Employees";
import Sims from "./Component/Inventory/Sims";
import Devices from "../src/Component/Inventory/Devices";
import AddClient from "./Component/Client/AddClient";
import AddInventory from "../src/Component/Inventory/AddInventory";
// import NotFoundPage from "./Component/NotFoundPage";
import Vendors from "./Component/Vendor/Vendors";
import UpdateInventory from "./Component/Inventory/UpdateInventory";
import UpdateDevice from "./Component/Inventory/UpdateDevice";
import UpdateSim from "./Component/Inventory/UpdateSim";
import AlertPopup from "./Component/Fixed/AlertPopup";
import AddLead from "./Component/Lead/AddLead";
import Leads from "./Component/Lead/Leads";
// React Notification
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";

export default class App extends Component {
  // state = { login: false };
  componentDidMount() {
    // //Modal.setAppElement("#modal");
    // const token = JSON.parse(localStorage.getItem("data"));
    // if (token) {
    //   this.setState({ login: true });
    // }
  }
  isAuth = () => {
    const token = JSON.parse(localStorage.getItem("data"));
    if (token) {
      return true;
    }
    return false;
  };
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route
            path="/"
            exact
            render={() =>
              this.isAuth() ? <Redirect to="/dashboard" /> : <Login />
            }
          />
          <Route>
            <Topbar />
            <Sidebar />
            <RightSidebar />
            <RightSideSettingMenu />

            <Route
              path="/dashboard"
              exact
              render={() =>
                this.isAuth() ? <Dashboard /> : <Redirect to="/" />
              }
            />
            <Route
              path="/clients"
              exact
              render={() => (this.isAuth() ? <Clients /> : <Redirect to="/" />)}
            />
            <Route
              path="/clients/add"
              exact
              render={() =>
                this.isAuth() ? <AddClient /> : <Redirect to="/" />
              }
            />
            <Route path="/clients/edit/:id" exact component={ClientEdit} />
            <Route
              path="/employees"
              exact
              render={() =>
                this.isAuth() ? <Employees /> : <Redirect to="/" />
              }
            />
            <Route
              path="/vendors"
              exact
              render={() => (this.isAuth() ? <Vendors /> : <Redirect to="/" />)}
            />
            <Route
              path="/orders"
              exact
              render={() => (this.isAuth() ? <Orders /> : <Redirect to="/" />)}
            />
            <Route
              path="/devices"
              exact
              render={() => (this.isAuth() ? <Devices /> : <Redirect to="/" />)}
            />
            <Route
              path="/inventory/add"
              exact
              render={() =>
                this.isAuth() ? <AddInventory /> : <Redirect to="/" />
              }
            />

            <Route
              path="/devices/update"
              exact
              render={() =>
                this.isAuth() ? <UpdateDevice /> : <Redirect to="/" />
              }
            />
            <Route
              path="/sims/update"
              exact
              render={() =>
                this.isAuth() ? <UpdateSim /> : <Redirect to="/" />
              }
            />
            <Route
              path="/devices/assign/:id"
              exact
              component={UpdateInventory}
            />
            <Route
              path="/sims"
              exact
              render={() => (this.isAuth() ? <Sims /> : <Redirect to="/" />)}
            />

            {/*All Type Alert Design*/}
            <Route
              path="/alert"
              exact
              render={() =>
                this.isAuth() ? <AlertPopup /> : <Redirect to="/" />
              }
            />
            {/*Lead Management*/}

            <Route
              path="/leads/add"
              exact
              render={() => (this.isAuth() ? <AddLead /> : <Redirect to="/" />)}
            />
            <Route
              path="/leads"
              exact
              render={() => (this.isAuth() ? <Leads /> : <Redirect to="/" />)}
            />
          </Route>
        </Switch>
        {/* <Route path="*" component={NotFoundPage} /> */}
        <NotificationContainer />
      </Router>
    );
  }
}
