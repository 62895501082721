import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllClients, getClientsBySearchValue } from "../../actions";

class Clients extends React.Component {
  //state for ad dand edit button
  state = { pageNumber: 0 };

  componentDidMount() {
    // this.props.fetchUser();
    this.props.getAllClients(this.state.pageNumber);
  }
  // render client card
  renderClientList = (array) => {
    return array.map((el) => {
      return (
        <div
          key={el.CLID}
          CLID={el.CLID}
          className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch"
          style={{ width: "auto" }}
        >
          <div className="card bg-light">
            <div className="card-header text-muted border-bottom-0">
              {`${el.CompanyName} (${el.ClientID})`}{" "}
            </div>
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-7">
                  <h4>
                    <b>
                      {el.ContactPerson
                        ? el.ContactPerson.toUpperCase()
                        : "UNKNOWN"}
                    </b>
                  </h4>
                  {/* <p className="text-muted text-sm">
                  <b>Role: </b> {el.ClientRole}{" "}
                </p> */}
                  <p className="text-muted text-sm mt-1">
                    <b style={{ fontWeight: 700 }}>Role: </b> {el.ClientRole}{" "}
                  </p>
                  <p className="text-muted text-sm mt-1">
                    <b style={{ fontWeight: 700 }}>Account Manager: </b>{" "}
                    {el.AccountManager}{" "}
                  </p>
                </div>
                <div className="col-5 text-center">
                  <img
                    src="../../dist/img/user1-128x128.png"
                    alt
                    className="img-circle img-fluid bg-gradient-primary"
                  />
                </div>
              </div>
              <div className="row">
                <ul className="ml-4 mb-0 fa-ul text-muted">
                  <li className="small mt-2">
                    <span className="fa-li">
                      <i className="fas fa-lg fa-building" />
                    </span>{" "}
                    Address:{" "}
                    {el.Address +
                      ", " +
                      el.City +
                      ", " +
                      el.State +
                      "-" +
                      el.PinCode +
                      ", " +
                      el.Country}
                  </li>
                  <li className="small mt-1">
                    <span className="fa-li">
                      <i className="fas fa-lg fa-envelope-open" />
                    </span>{" "}
                    Email : {el.Email}
                  </li>
                  <li className="small mt-1">
                    <span className="fa-li">
                      <i className="fas fa-lg fa-phone" />
                    </span>{" "}
                    Phone #: {el.Mobile}
                  </li>
                  <li className="small mt-1">
                    <span className="fa-li">
                      <i className="fas fa-lg fa-rupee-sign" />
                    </span>{" "}
                    GST #: {el.GST ? el.GST : "Not Available"}
                  </li>
                </ul>
              </div>
            </div>

            <div className="card-footer">
              {/* <div className="text-left"></div> */}
              <div className="text-right">
                <Link
                  to={`/clients/edit/${el.CLID}`}
                  className="btn btn-sm btn-secondary"
                  style={{ marginRight: "5px" }}
                >
                  <i className="fas fa-cogs" /> Edit
                </Link>
                <a
                  href="fakeurl"
                  className="btn btn-sm bg-teal"
                  style={{ marginRight: "5px" }}
                >
                  <i className="fas fa-file-signature" /> Notes
                </a>
                <a href="fakeurl" className="btn btn-sm btn-primary">
                  <i className="fas fa-user" /> View Profile
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  //render Client Data for table
  renderDataClient = (clients) => {
    return clients.map((el) => {
      return (
        <tr key={clients.indexOf(el) + 1}>
          <td>{this.state.pageNumber + clients.indexOf(el) + 1}</td>
          <td>{el.ClientID}</td>
          <td>{el.CompanyName}</td>
          <td>{el.ContactPerson}</td>
          <td>{el.Mobile}</td>
          <td>{el.ClientRole}</td>
          <td>{el.AccountManager}</td>
          <td>{el.ClientID}</td>
          <td>{el.ClientID}</td>
          <td>
            <Link
              href="javascript:void(0)"
              className="text-inverse pr-10"
              title
              data-toggle="tooltip"
              data-original-title="Edit"
              to={`/clients/edit/${el.CLID}`}
            >
              <i className="zmdi zmdi-edit txt-warning" />
            </Link>
            <a
              href="javascript:void(0)"
              className="text-inverse"
              title
              data-toggle="tooltip"
              data-original-title="Delete"
            >
              <i className="zmdi zmdi-delete txt-danger" />
            </a>
          </td>
        </tr>
      );
    });
  };

  //Search Client
  SearchClient = (e) => {
    this.props.getClientsBySearchValue(e.target.value);
  };

  //Next Button Handler

  nextButtonHandler = () => {
    if (this.props.clients.numClients >= this.state.pageNumber) {
      this.props.getAllClients(this.state.pageNumber + 20);
      let newPageNumber = this.state.pageNumber + 20;
      this.setState({ pageNumber: newPageNumber });
    }
  };

  //Previous Button Handler

  perviousButtonHandler = () => {
    if (this.state.pageNumber > 0) {
      this.props.getAllClients(this.state.pageNumber - 20);
      let newPageNumber = this.state.pageNumber - 20;
      this.setState({ pageNumber: newPageNumber });
    }
  };

  //Edit Button Handler
  editButtonClickHandler = (e) => {
    // this.props.fetchClientByClid();
    console.log(e.target.parentNode.parentNode.parentNode.parentNode);
  };
  //cards

  renderCards = () => {
    return (
      <div>
        <div className="row ml-2 mr-2">
          <div className="col-lg-3 col-6">
            {/* small card */}
            <div className="small-box bg-info">
              <div className="inner">
                <h3 style={{ color: "#fff" }}>
                  {this.props.clients.report &&
                    this.props.clients.report[0].count}
                </h3>

                <p style={{ color: "#fff" }}>
                  {this.props.clients.report &&
                    this.props.clients.report[0].title}
                </p>
              </div>
              <div className="icon">
                <i className="fas fa-shopping-cart" />
              </div>
              <a href="fakeurl" className="small-box-footer">
                More info <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>
          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small card */}
            <div className="small-box bg-success">
              <div className="inner">
                <h3 style={{ color: "#fff" }}>
                  {this.props.clients.report &&
                    this.props.clients.report[1].count}
                </h3>
                <p style={{ color: "#fff" }}>
                  {this.props.clients.report &&
                    this.props.clients.report[1].title}
                </p>
              </div>
              <div className="icon">
                <i className="fas fa-arrow-circle-right" />
              </div>
              <a href="fakeurl" className="small-box-footer">
                More info <i className="fa fa-arrow-circle-right" />
              </a>
            </div>
          </div>
          {/* ./col */}

          <div className="col-lg-3 col-6">
            {/* small card */}
            <div className="small-box bg-warning">
              <div className="inner">
                <h3 style={{ color: "#fff" }}>
                  {this.props.clients.report &&
                    this.props.clients.report[2].count}
                </h3>
                <p style={{ color: "#fff" }}>
                  {this.props.clients.report &&
                    this.props.clients.report[2].title}
                </p>
              </div>
              <div className="icon">
                <i className="fas fa-user-plus" />
              </div>
              <a
                href="fakeurl"
                className="small-box-footer"
                style={{ color: "#fff" }}
              >
                More info <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>
          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small card */}
            <div className="small-box bg-danger">
              <div className="inner">
                <h3 style={{ color: "#fff" }}>
                  {this.props.clients.report &&
                    this.props.clients.report[3].count}
                </h3>
                <p style={{ color: "#fff" }}>
                  {this.props.clients.report &&
                    this.props.clients.report[3].title}
                </p>
              </div>
              <div className="icon">
                <i className="fas fa-chart-pie" />
              </div>
              <a href="fakeurl" className="small-box-footer">
                More info <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>
          {/* ./col */}
        </div>
      </div>
    );
  };

  //main render function
  render() {
    return (
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* Title */}
          <div className="row heading-bg">
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <h5 className="txt-dark" style={{ float: "left" }}>
                Client Details
              </h5>
              <Link
                to="/clients/add"
                className="btn btn-primary"
                style={{ marginLeft: 20 }}
              >
                Add New
              </Link>
            </div>
            {/* Breadcrumb */}
            <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
              <ol className="breadcrumb">
                <li>
                  <Link
                    to="/"
                    onClick={() => {
                      setTimeout(() => {
                        window.location.reload(true);
                      }, 50);
                    }}
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="active">
                  <span>Show Client</span>
                </li>
              </ol>
            </div>
            {/* /Breadcrumb */}
          </div>
          {/* /Title */}
          {/* Row */}
          <div className="row">
            <div className="col-sm-12">
              {/* Main content */}
              <section className="content">
                {this.renderCards()}
                {/* Default box */}
                <div className="card card-solid">
                  <div
                    className="row"
                    style={{ padding: "20px", alignItems: "center" }}
                  >
                    <div className="col-12 col-md-6">
                      <div
                        className="dataTables_info"
                        id="example1_info"
                        role="status"
                        aria-live="polite"
                      >
                        {this.props.clients.clients &&
                        this.props.clients.clients.length > 0
                          ? `Showing ${this.state.pageNumber + 1} to ${
                              this.state.pageNumber +
                              this.props.clients.clients.length
                            } of
                  ${this.props.clients.numClients} entries`
                          : "No record found!"}
                      </div>
                    </div>
                    <div
                      className="col-12 col-md-6"
                      style={{ textAlign: "right" }}
                    >
                      <div id="example1_filter " className="">
                        {/* <form className="form-inline ml-3">
                    <div className="input-group input-group-sm">
                      <input
                        className="form-control form-control-navbar"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        autoComplete="off"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-navbar" type="submit">
                          <i className="fas fa-search" />
                        </button>
                      </div>
                    </div>
                  </form> */}

                        <label
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            marginBottom: "0",
                          }}
                        >
                          <span
                            style={{ marginRight: "4px", fontWeight: "normal" }}
                          >
                            {"Search: "}{" "}
                          </span>

                          <input
                            onChange={this.SearchClient}
                            type="search"
                            className="form-control form-control-sm"
                            placeholder
                            aria-controls="example1"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="card-body pb-0">
                    <div className="row d-flex align-items-stretch">
                      {this.props.clients.clients
                        ? this.renderClientList(this.props.clients.clients)
                        : ""}
                    </div>
                  </div>
                  {/* /.card-body */}
                  <div className="card-footer">
                    <nav aria-label="Contacts Page Navigation">
                      <div className="pagination justify-content-center m-0">
                        <button
                          className="btn btn-success mr-2"
                          onClick={this.perviousButtonHandler}
                        >
                          Previous
                        </button>
                        <button
                          className="btn btn-success"
                          onClick={this.nextButtonHandler}
                        >
                          Next
                        </button>
                      </div>
                    </nav>
                  </div>
                  {/* /.card-footer */}
                </div>
                {/* /.card */}
              </section>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-sm-12">
              <div className="panel panel-default card-view">
                <div className="panel-wrapper collapse in">
                  <div className="panel-body">
                    <div class="dt-buttons">
                      <a
                        class="dt-button buttons-copy buttons-html5"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>Copy</span>
                      </a>
                      <a
                        class="dt-button buttons-csv buttons-html5"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>CSV</span>
                      </a>
                      <a
                        class="dt-button buttons-excel buttons-html5"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>Excel</span>
                      </a>
                      <a
                        class="dt-button buttons-pdf buttons-html5"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>PDF</span>
                      </a>
                      <a
                        class="dt-button buttons-print"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>Print</span>
                      </a>
                    </div>
                    <div style={{ float: "right" }} class="dataTables_filter">
                      <label>
                        Search:{" "}
                        <input
                          type="search"
                          class=""
                          onChange={this.SearchClient}
                          placeholder=""
                          aria-controls="example"
                        />
                      </label>
                    </div>
                    <div
                      class="dataTables_info"
                      style={{ textAlign: "center" }}
                      role="status"
                      aria-live="polite"
                    >
                      {" "}
                      {!this.props.clients.clients
                        ? "null"
                        : `Total: ${this.props.clients.numClients}`}
                    </div>

                    <div className="table-wrap" style={{ paddingTop: "20px" }}>
                      <div className="table-responsive">
                        <table
                          id="example"
                          className="table table-hover display  pb-30"
                        >
                          <thead>
                            <tr>
                              <th>Sno</th>
                              <th>ClientID</th>
                              <th>Company Name</th>
                              <th>Contact Person</th>
                              <th>Mobile No</th>
                              <th>CLient Type</th>
                              <th>Account Manager</th>
                              <th>Added Date</th>
                              <th>Added By</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {!this.props.clients.clients
                              ? "no data"
                              : this.renderDataClient(
                                  this.props.clients.clients
                                )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="col-sm-12"
                      style={{ textAlign: "right", marginTop: "20px" }}
                    >
                      <button
                        className="btn btn-success"
                        name="previous"
                        type="button"
                        style={{ marginRight: "20px" }}
                        onClick={this.perviousButtonHandler}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-success"
                        name="next"
                        type="button"
                        onClick={this.nextButtonHandler}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          /Row */}
          {/* Footer */}
          <footer className="footer container-fluid pl-30 pr-30">
            <div className="row">
              <div className="col-sm-12">
                <p>2017 © Libicon. Pampered by Libi Technologies</p>
              </div>
            </div>
          </footer>
          {/* /Footer */}
        </div>
      </div>
    );
  }
}

//Value pick form redux store to props

const mapStateToProp = (state) => {
  return {
    clients: state.clients,
  };
};

//Connect with redux form with local redux store
export default connect(mapStateToProp, {
  getAllClients,
  getClientsBySearchValue,
})(Clients);
