import * as TYPES from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.FETCH_USER_DETAIL:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        user: action.payload,
      };
    // case TYPES.FETCH_NOTIFICATION:
    //   if (!action.payload) {
    //     return state;
    //   }
    //   return {
    //     ...state,
    //     notification: action.payload.notifications,
    //   };
    default:
      return state;
  }
};
