export const SIGN_IN = "SING_IN";
export const SIGN_OUT = "SING_OUT";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_DETAIL = "FETCH_USER_DETAIL";

export const FETCH_ALL_CLIENTS = "FETCH_ALL_CLIENTS";
export const FETCH_CLIENTS_BY_SEARCH = "FETCH_CLIENTS_BY_SEARCH";
export const FETCH_CLIENT_BY_CLID = "FETCH_CLIENT_BY_CLID";

export const FETCH_ALL_EMPLOYEE = "FETCH_ALL_EMPLOYEE";
export const FETCH_EMPLOYEE_BY_SEARCH = "FETCH_EMPLOYEE_BY_SEARCH";
export const FETCH_ALL_EMPLOYEE_NAME = "FETCH_ALL_EMPLOYEE_NAME";
export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";

export const FETCH_ALL_BATCH_NUMBER = "FETCH_ALL_BATCH_NUMBER";

export const FETCH_ALL_VENDOR = "FETCH_ALL_VENDOR";

export const FETCH_ALL_DEVICE = "FETCH_ALL_DEVICE";
export const FETCH_ALL_DEVICE_MODEL_NAME = "FETCH_ALL_DEVICE_MODEL_NAME";
export const FETCH_DEVICE_BY_SEARCH = "FETCH_DEVICE_BY_SEARCH";

export const FETCH_ALL_SIM = "FETCH_ALL_SIM";
export const FETCH_SIM_BY_SEARCH = "FETCH_SIM_BY_SEARCH";

export const FETCH_ORDER_ITEMS_BY_CDID = "FETCH_ORDER_ITEMS_BY_CDID";
export const FETCH_ORDER_CDID = "FETCH_ORDER_CDID";
export const FETCH_ORDER_BY_SEARCH = "FETCH_ORDER_BY_SEARCH";
export const FETCH_ALL_ORDER_NUMBER = "FETCH_ALL_ORDER_NUMBER";
export const FETCH_ALL_ORDERS = "FETCH_ALL_ORDERS";
export const ADD_ORDER_NOTE = "ADD_ORDER_NOTE";

export const FETCH_ALL_LEADS = "FETCH_ALL_LEADS";
export const FETCH_LEAD_NOTE = "FETCH_LEAD_NOTE";
export const ADD_LEAD_NOTE = "ADD_LEAD_NOTE";
