import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllSim, getSimBySearchValue } from "../../actions/index";
import Modal from "../Fixed/Modal";

class Sims extends React.Component {
  state = { pageNumber: 0 };
  
  componentDidMount() {
    this.props.getAllSim(this.state.pageNumber);
  }

  //Device Location

  Location = ["Employee", "Dealer", "Vender", "User", "Office"];
  //render Device Data for table
  renderDataSim = (sims) => {
    return sims.map((el) => {
      return (
        <tr key={sims.indexOf(el) + 1}>
          <td>{this.state.pageNumber + sims.indexOf(el) + 1}</td>
          <td>{"Direct"}</td>
          <td>{el.CompanyName}</td>
          <td>{el.SimNo}</td>
          <td>{el.MobileNo}</td>
          <td>{el.SimLocation}</td>
          <td>{el.LocationName}</td>
          <td>{el.Status}</td>
          <td>{el.CreatedDate}</td>
          <td>{el.AddedBy}</td>
          <td>
            <Link
              href="javascript:void(0)"
              className="text-inverse pr-10"
              type="button"
              title
              data-toggle="modal"
              data-target="#editsimmodal"
              data-whatever="@mdo"
              data-original-title="Edit Sim"
            >
              <i className="zmdi zmdi-edit txt-warning" />
            </Link>
            <a
              href="javascript:void(0)"
              className="text-inverse"
              title
              data-toggle="tooltip"
              data-original-title="Notes"
            >
              <i className="zmdi zmdi-book txt-success" />
            </a>
          </td>
        </tr>
      );
    });
  };

  //Search Device
  SearchSim = (e) => {
    this.props.getSimBySearchValue(e.target.value);
  };

  //Next Button Handler

  nextButtonHandler = () => {
    if (this.props.sims.totalSims >= this.state.pageNumber) {
      this.props.getAllSim(this.state.pageNumber + 10);
      let newPageNumber = this.state.pageNumber + 10;
      this.setState({ pageNumber: newPageNumber });
    }
  };

  //Previous Button Handler

  perviousButtonHandler = () => {
    if (this.state.pageNumber > 0) {
      this.props.getAllSim(this.state.pageNumber - 10);
      let newPageNumber = this.state.pageNumber - 10;
      this.setState({ pageNumber: newPageNumber });
    }
  };

  //Edit Button Handler
  editButtonClickHandler = (e) => {
    // this.props.fetchClientByClid();
    console.log(e.target.parentNode.parentNode.parentNode.parentNode);
  };
  //cards

  //main render function
  render() {
    return (
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* Title */}
          <div className="row heading-bg">
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <h5 className="txt-dark" style={{ float: "left" }}>
                Sim Details
              </h5>
              <Link
                to="/inventory/add"
                className="btn btn-primary"
                style={{ marginLeft: 20 }}
              >
                Add New
              </Link>
            </div>
            {/* Breadcrumb */}
            <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
              <ol className="breadcrumb">
                <li>
                  <Link
                    to="/"
                    onClick={() => {
                      setTimeout(() => {
                        window.location.reload(true);
                      }, 50);
                    }}
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="active">
                  <span>Show Sims</span>
                </li>
              </ol>
            </div>
            {/* /Breadcrumb */}
          </div>
          {/* /Title */}
          {/* Row */}

          <div className="row">
            <div className="col-sm-12">
              <div className="panel panel-default card-view">
                <div className="panel-wrapper collapse in">
                  <div className="panel-body">
                    <div class="dt-buttons">
                      <a
                        class="dt-button buttons-copy buttons-html5"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>Copy</span>
                      </a>
                      <a
                        class="dt-button buttons-csv buttons-html5"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>CSV</span>
                      </a>
                      <a
                        class="dt-button buttons-excel buttons-html5"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>Excel</span>
                      </a>
                      <a
                        class="dt-button buttons-pdf buttons-html5"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>PDF</span>
                      </a>
                      <a
                        class="dt-button buttons-print"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>Print</span>
                      </a>
                    </div>
                    <div style={{ float: "right" }} class="dataTables_filter">
                      <label>
                        Search:{" "}
                        <input
                          type="search"
                          class=""
                          onChange={this.SearchSim}
                          placeholder=""
                          aria-controls="example"
                        />
                      </label>
                    </div>
                    <div
                      class="dataTables_info"
                      style={{ textAlign: "center" }}
                      role="status"
                      aria-live="polite"
                    >
                      {this.props.sims.sims &&
                      this.props.sims.sims.sim.length > 0
                        ? `Showing ${this.state.pageNumber + 1} to ${
                            this.state.pageNumber +
                            this.props.sims.sims.sim.length
                          } of
                  ${this.props.sims.totalSims} entries`
                        : "No record found!"}
                    </div>

                    <div className="table-wrap" style={{ paddingTop: "20px" }}>
                      <div className="table-responsive">
                        <table
                          id="example"
                          className="table table-hover display  pb-30"
                        >
                          <thead>
                            <tr>
                              <th>Sno</th>
                              <th>Vendor</th>
                              <th>Sim Company</th>
                              <th>Sim No</th>
                              <th>Mobile No</th>
                              <th>Location</th>
                              <th>Location Name</th>
                              <th>Status</th>
                              <th>Added Date</th>
                              <th>Added By</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {!this.props.sims.sims
                              ? "no data"
                              : this.renderDataSim(this.props.sims.sims.sim)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="col-sm-12"
                      style={{ textAlign: "right", marginTop: "20px" }}
                    >
                      <button
                        className="btn btn-success"
                        name="previous"
                        type="button"
                        style={{ marginRight: "20px" }}
                        onClick={this.perviousButtonHandler}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-success"
                        name="next"
                        type="button"
                        onClick={this.nextButtonHandler}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Edit Device Model */}
          <Modal header={`Edit Sim#86190261525`} id="editsimmodal">
            <div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label className="control-label mb-10">IMEI</label>

                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputuname"
                      placeholder={153}
                    />
                  </div>
                  {/* <div className="form-group">
                    <label className="control-label mb-10">Vendor</label>
                    <Field
                      component="select"
                      className="form-control"
                      name="vendor"
                    >
                      <option values="">Select Vendor</option>
                      {!this.props.vendor.vendors
                        ? "No Employee"
                        : this.renderOptionList(this.props.vendor.vendors)}
                    </Field>
                  </div> */}
                  {/* <div className="form-group">
                    <label className="control-label mb-10">Batch</label>

                    <Field
                      component="select"
                      className="form-control"
                      name="batchNo"
                      tabIndex={1}
                    >
                      <option value="">Select Batch </option>
                      {!this.props.batch.batchNumber
                        ? "No Batch"
                        : this.renderOptionList(this.props.batch.batchNumber)}
                    </Field>
                  </div> */}
                  {/* <div className="form-group">
                    <label className="control-label mb-10">Device Model</label>

                    <Field
                      component="select"
                      className="form-control"
                      name="model"
                      tabIndex={1}
                    >
                      <option value="">Select model </option>
                      {!this.props.deviceModel.deviceModel
                        ? "No Employee"
                        : this.renderOptionList(
                            this.props.deviceModel.deviceModel
                          )}
                    </Field>
                  </div> */}
                  {/* <div className="form-group">
                    <label className="control-label mb-10">Location</label>
                    <Field
                      component="select"
                      className="form-control"
                      name="location"
                      tabIndex={1}
                      initialValues="Office"
                      enalble
                    >
                      <option value="">Select Location </option>
                      {this.renderOptionList(this.Location)}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">Location Name</label>
                    <Field
                      component="select"
                      className="form-control"
                      name="locationName"
                      enalble
                    >
                      <option values="">Select Location Name</option>
                      {!this.props.employee.employeeName
                        ? "No Employee"
                        : this.renderOptionList(
                            this.props.employee.employeeName
                          )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">Status</label>
                    <Field
                      component="select"
                      className="form-control"
                      name="status"
                      tabIndex={1}
                      enalble
                    >
                      <option value="">Select Status </option>
                      {this.renderOptionList(this.Status)}
                    </Field>
                  </div> */}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-warning btn-lable-wrap right-label"
                  id="sa-params"
                >
                  <span className="btn-text" data-dismiss="modal">
                    Close
                  </span>{" "}
                  <span className="btn-label">
                    <i className="fa fa-times" />{" "}
                  </span>
                </button>
                <button
                  className="btn btn-success btn-icon right-icon"
                  id="sa-success"
                >
                  <span>Update</span> <i className="fa fa-check" />{" "}
                </button>
              </div>
            </div>
          </Modal>

          {/* Footer */}
          <footer className="footer container-fluid pl-30 pr-30">
            <div className="row">
              <div className="col-sm-12">
                <p>2017 © Libicon. Pampered by Libi Technologies</p>
              </div>
            </div>
          </footer>
          {/* /Footer */}
        </div>
      </div>
    );
  }
}

//Value pick form redux store to props

const mapStateToProp = (state) => {
  // console.log(state.device.devices);
  return {
    sims: state.sim,
  };
};

//Connect with redux form with local redux store
export default connect(mapStateToProp, {
  getAllSim,
  getSimBySearchValue,
})(Sims);
