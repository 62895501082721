import React from "react";
import { connect } from "react-redux";


import { Field, formValues, reduxForm } from "redux-form";
import {
  getAllEmployeeName,
  checkIMEIExistence,
  fetchAllOrderNumber,
  updateDevice,
  getAllDeviceModelName,
  fetchOrderByCdid,
  fetchOrderItemByCdid,
} from "../../actions/index";

class UpdateDevice extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    data: [],
    imeiArray: [],
    modelName: [],
    value: "",
    typingTimeout: 0,
    location: "",
    locationName: "",
    status: "",
    orderID: "",
  };
  componentDidMount() {
    // this.props.fetchUser();
    //console.log(this.props.token);
    this.props.getAllEmployeeName();
    this.props.getAllDeviceModelName();
  }

  componentDidUpdate() {
    const node = this.myRef.current;
    node.value = "";
  }

  //Ajax request to add device on server
  updateDevice = (orderNo) => {
    // this.props.addDevice(this.state.data);
    // this.props.checkIMEIExistence(this.state.devices);
    let { imeiArray, location, locationName } = this.state;
    let orderId = `LIBIO${orderNo}`;
    this.props.updateDevice(imeiArray, orderId, location, locationName);
  };

  //add devices to state from barcode scanner or manual type
  addDeviceToState = (e) => {
    e.preventDefault();
    let imei = e.target.value;
    let imeiArray = imei.split("\n");
    console.log(imeiArray);
    if (this.props.formValues.values) {
      console.log(this.props.formValues.values);
    }

    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.state.typingTimeout = setTimeout(() => {
      let newData = this.state.data;
      let newDevices = this.state.imeiArray;
      let newModelName = this.state.modelName;
      imeiArray.forEach((imei) => {
        const data = {
          ...this.props.formValues.values,
          imei,
          whobrought: "Company",
          warranty: "12",
          category: "GPS System",
        };
        const model = this.props.formValues.values.model;
        if (
          imei.length > 0 &&
          imei.length <= 15 &&
          !this.state.imeiArray.includes(imei)
        ) {
          newData.push(data);
          newDevices.push(imei);
          newModelName.push(model);
        }
      });
      this.setState({
        data: newData,
        imeiArray: newDevices,
        locationName: this.props.formValues.values.locationName,
        location: this.props.formValues.values.location,
        modelName: newModelName,
      });
    }, 100);
  };

  //Remove data from state
  removeDataFromState = (imei) => {
    // console.log(imei);
    let newData = this.state.data;
    let newDevices = this.state.imeiArray;
    let newModelName = this.state.modelName;

    var index = newDevices.indexOf(imei);
    // console.log(index);
    if (index !== -1) {
      newDevices.splice(index, 1);
      newData.splice(index, 1);
      newModelName.splice(index, 1);
    }
    // console.log(newData, newDevices, newModelName);
    this.setState({
      data: newData,
      imeiArray: newDevices,
      modelName: newModelName,
    });
  };

  //render Device from state to screen
  renderDeviceList = () => {
    if (this.state.data.length > 0) {
      return this.state.data.map((el) => {
        return (
          <tr>
            <td>{el.orderNo}</td>
            <td>{el.model}</td>
            <td>{el.location}</td>
            <td>{el.locationName}</td>
            <td>{el.status}</td>
            <td>{el.imei}</td>
            <td>
              {" "}
              <a
                href="javascript:void(0)"
                className="text-inverse"
                title
                data-toggle="tooltip"
                data-original-title="Delete"
                onClick={() => {
                  this.removeDataFromState(el.imei);
                }}
              >
                <i className="zmdi zmdi-close txt-danger" />
              </a>
            </td>
          </tr>
        );
      });
    }
  };

  //Render Options in select tag

  renderOptionList = (array) => {
    return array.map((el) => {
      return (
        <option key={array.indexOf(el) + 1} value={el}>
          {el}
        </option>
      );
    });
  };

  //Device Model List
  // DeviceModel = [
  //   "A20S",
  //   "AT3",
  //   "ASSET 20K MAH",
  //   "BW08",
  //   "BW09",
  //   "CYCLE LOCK",
  //   "DSF22",
  //   "Dash Cam",
  //   "E-Lock",
  //   "EIT",
  //   "ET300",
  //   "ET300+",
  //   "ET301",
  //   "EV02",
  //   "FA23",
  //   "FM1100",
  //   "FMB120",
  //   "FMB910",
  //   "FMB920",
  //   "G01",
  //   "G02",
  //   "G05",
  //   "G19",
  //   "G200",
  //   "G300",
  //   "G900",
  //   "Garmin Etrex 10",
  //   "Garmin Etrex 20X",
  //   "Garmin Etrex 30x",
  //   "Garmin 12H",
  //   "Garmin 30X",
  //   "Garmin 64S",
  //   "Garmin 78S",
  //   "GF07",
  //   "GF09",
  //   "GPSONE",
  //   "GS05A",
  //   "GT06N",
  //   "GT02M",
  //   "GT02",
  //   "GT02D",
  //   "GT06F",
  //   "GT03I",
  //   "GT300",
  //   "GT800",
  //   "GW300",
  //   "Harness",
  //   "IDCARD",
  //   "JV200",
  //   "LIBI140",
  //   "LibiTrack",
  //   "LT01",
  //   "LT03",
  //   "LT05",
  //   "LT05+",
  //   "LT05 AC",
  //   "LT08",
  //   "LT08A",
  //   "LT08M",
  //   "LT09",
  //   "LT10",
  //   "LT100",
  //   "LT13",
  //   "LT21",
  //   "MT01HV",
  //   "MT01HV",
  //   "MT03C",
  //   "MT08",
  //   "MT06C",
  //   "MTX1",
  //   "OB22",
  //   "OBD",
  //   "PetTracker",
  //   "S003T",
  //   "S02",
  //   "S20",
  //   "T58",
  //   "T8608D",
  //   "T8806",
  //   "TD08",
  //   "TK06",
  //   "TK06B",
  //   "TK101",
  //   "TK101 mini",
  //   "TK103",
  //   "TK99",
  //   "TR05",
  //   "TR08",
  //   "TR21",
  //   "W26",
  //   "Watch Tracker",
  //   "WeTrack2",
  //   "Other",
  // ];

  //Device Location

  Location = ["Employee", "Dealer", "Vendor", "User", "Office"];

  //Device Status

  Status = [
    "New",
    "Replaced",
    "Installed",
    "Under Repair",
    "Sold",
    "Repaired",
    "Void",
    "Other",
  ];

  //Render Error Message
  renderErrorMessage = (meta) => {
    if (meta.touched) {
      return <div className="form__error">{meta.error}</div>;
    } else {
      return null;
    }
  };

  //render count card
  renderCount = (orderId) => {
    const models = this.state.modelName;
 
    console.log(orderId);
    var uniqueModels = this.props.order[orderId]
      ? this.props.order[orderId]
      : [];
    

    {
      return uniqueModels.map((e,i) => {
        return (
          <div>
            {e.model + ': ' + ( 
              this.props.order[orderId] &&  this.props.order[orderId][i].attachedDevices ? this.props.order[orderId][i].attachedDevices : models.filter((x) => x === e.model).length 
             ) + 
            '/' + e.quantity}
          </div>
        );
      });
    }
  };

  //convert input to redux field

  renderInput = (formProps) => {
    
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <input
          className="form-control"
          type={formProps.type}
          placeholder={formProps.placeholder}
          {...formProps.input}
        />
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };
  //convert select to redux field

  renderSelectTag = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <select
          className="form-control"
          type={formProps.type}
          {...formProps.input}
        />
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };

  // onSubmit = (e) => {
  //   e.preventDefault();
  // };

  renderFormFields = () => {
    const { handleSubmit, pristine, submitting } = this.props;
    return (
      <div className="form-wrap">
        <div className="form-horizontal">
          <div className="form-body">
            <h6 className="txt-dark capitalize-font">
              <i className="zmdi zmdi-account mr-10" />
              Device Add Info
            </h6>
            {/*for upload excel file and show them on screen}
            {/* <div>
              <input
                type="file"
                onChange={this.fileHandler.bind(this)}
                style={{ padding: "10px" }}
              />
            </div>
            <button onClick={this.showTable}>Show</button> */}
            <hr className="light-grey-hr" />
            <div className="row">
              <div
                className="col-sm-12"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "20px",
                }}
              >
                {this.renderCount(this.props.orderId)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="panel panel-default card-view">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div className="table-wrap">
                        <div className="table-responsive">
                          <table className="table table-hover display  pb-30">
                            <thead>
                              <tr>
                                <th>Order#</th>
                                <th>Model</th>
                                <th>Location</th>
                                <th>Location Name</th>
                                <th>Status</th>
                                <th>IMEI</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Field
                                    component="select"
                                    className="form-control"
                                    name="orderNo"
                                    tabIndex={1}
                                    disabled={!!this.props.order.currentOrder}
                                  >
                                    <option value="">Select Order </option>
                                    <option value="NA">Not Applicable</option>
                                    {this.props.order.currentOrder && (
                                      <option
                                        value={
                                          this.props.order.currentOrder.orderId
                                        }
                                      >
                                        {this.props.order.currentOrder.orderId}
                                      </option>
                                    )}
                                    {!this.props.order.orderNumber
                                      ? "No Order"
                                      : this.renderOptionList(
                                          this.props.order.orderNumber
                                        )}
                                  </Field>
                                </td>
                                <td>
                                  <Field
                                    component="select"
                                    className="form-control"
                                    name="model"
                                    tabIndex={1}
                                  >
                                    <option value="">Select model </option>
                                    {!this.props.deviceModel.deviceModel
                                      ? "No Employee"
                                      : this.renderOptionList(
                                          this.props.deviceModel.deviceModel
                                        )}
                                  </Field>
                                </td>
                                <td>
                                  <Field
                                    component="select"
                                    className="form-control"
                                    name="location"
                                    tabIndex={1}
                                    disabled={!!this.props.order.currentOrder}
                                  >
                                    <option value="">Select Location </option>
                                    {this.renderOptionList(this.Location)}
                                  </Field>
                                </td>
                                <td>
                                  <Field
                                    component="select"
                                    className="form-control"
                                    name="locationName"
                                    disabled={!!this.props.order.currentOrder}
                                  >
                                    <option values="">
                                      Select Location Name
                                    </option>
                                    {this.props.order.currentOrder && (
                                      <option
                                        value={
                                          this.props.order.currentOrder.CompanyName.split(
                                            "/"
                                          )[1]
                                        }
                                      >
                                        {
                                          this.props.order.currentOrder.CompanyName.split(
                                            "/"
                                          )[1]
                                        }
                                      </option>
                                    )}
                                    ||
                                    {!this.props.employee.employeeName
                                      ? "No Employee"
                                      : this.renderOptionList(
                                          this.props.employee.employeeName
                                        )}
                                  </Field>
                                </td>
                                <td>
                                  <Field
                                    component="select"
                                    className="form-control"
                                    name="status"
                                    tabIndex={1}
                                  >
                                    <option value="">Select Status </option>
                                    {this.renderOptionList(this.Status)}
                                  </Field>
                                </td>

                                <td>
                                  <textarea
                                    name="imei"
                                    ref={this.myRef}
                                    className="form-control"
                                    onChange={(e) => {
                                      return this.addDeviceToState(e);
                                    }}
                                    placeholder="eg.8600020132565"
                                    disabled={
                                      !(
                                        this.props.formValues &&
                                        this.props.formValues.values.model &&
                                        this.props.formValues.values.location &&
                                        this.props.formValues.values
                                          .locationName
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="seprator-block" />
          <h6 className="txt-dark capitalize-font">
            <i className="zmdi zmdi-account-box mr-10" />
            Device List
          </h6>
          <hr className="light-grey-hr" />
          <div className="row">
            <div className="col-sm-12">
              <div className="panel panel-default card-view">
                <div className="panel-wrapper collapse in">
                  <div className="panel-body">
                    <div className="table-wrap">
                      <div className="table-responsive">
                        <table className="table table-hover display  pb-30">
                          <thead>
                            <tr>
                              <th>Order#</th>
                              <th>Model</th>
                              <th>Location</th>
                              <th>Location Name</th>
                              <th>Status</th>
                              <th>IMEI</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>{this.renderDeviceList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-actions mt-10">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-offset-3 col-md-9">
                    <button
                      type="button"
                      className="btn btn-success  mr-10"
                      onClick={() => this.updateDevice(this.props.orderId)}
                    >
                      Submit
                    </button>
                    <button type="button" className="btn btn-default">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6"> </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div id="home_15" className="tab-pane fade active in" role="tabpanel">
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default card-view">
              <div className="panel-heading">
                <div className="pull-left">
                  <h6 className="panel-title txt-dark">Add Device Details</h6>
                </div>
                <div className="clearfix" />
              </div>
              <div className="panel-wrapper collapse in">
                <div className="panel-body">
                  <div className="row">
                    <div className="col-md-12">{this.renderFormFields()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
//Value pick form redux store to props

const mapStateToProp = (state) => {
  return {
    employee: state.employee,
    order: state.order,
    client: state.client,
    deviceModel: state.device,
    formValues: state.form.DeviceForm,
    initialValues: {
      orderNo:
        (state.order.currentOrder && state.order.currentOrder.orderId) || "",
      location: (state.order.currentOrder && "Dealer") || "",
      status: "New",
      locationName:
        (state.order.currentOrder &&
          state.order.currentOrder.CompanyName.split("/")[1]) ||
        "",
    },
  };
};

//Client Side Form validation
const validate = (formValues) => {
  const errors = {};
  // var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!formValues.username) {
    errors.username = "You must enter your username";
  } else if (formValues.username.length < 5) {
    errors.username = "Employee Id must be atleast 7 long";
  }
  if (!formValues.pswd) {
    errors.pswd = "You must enter the password";
  } else if (formValues.pswd.length < 6) {
    errors.pswd = "Password must be atleast 6 long charector";
  }
  return errors;
};

//Connect with redux form with local redux store
export default connect(mapStateToProp, {
  getAllEmployeeName,
  fetchAllOrderNumber,
  fetchOrderByCdid,
  checkIMEIExistence,
  updateDevice,
  getAllDeviceModelName,
  fetchOrderItemByCdid,
})(
  reduxForm({
    form: "DeviceForm",
    enableReinitialize: true,
    validate,
  })(UpdateDevice)
);
