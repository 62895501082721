import React, { Component } from "react";

export default class RightSlider extends Component {
  render() {
    return (
      <div className="fixed-sidebar-right">
        <ul className="right-sidebar">{this.props.children}</ul>
      </div>
    );
  }
}
