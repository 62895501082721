import React, { Component } from "react";

export default class RightSideSettingMenu extends Component {
  render() {
    return (
      <div>
        <div>
          {/* Right Setting Menu */}
          <div className="setting-panel">
            <ul className="right-sidebar nicescroll-bar pa-0">
              <li className="layout-switcher-wrap">
                <ul>
                  <li>
                    <span className="layout-title">Scrollable header</span>
                    <span className="layout-switcher">
                      <input
                        type="checkbox"
                        id="switch_3"
                        className="js-switch"
                        data-color="#2ecd99"
                        data-secondary-color="#dedede"
                        data-size="small"
                      />
                    </span>
                    <h6 className="mt-30 mb-15">Theme colors</h6>
                    <ul className="theme-option-wrap">
                      <li id="theme-1" className="active-theme">
                        <i className="zmdi zmdi-check" />
                      </li>
                      <li id="theme-2">
                        <i className="zmdi zmdi-check" />
                      </li>
                      <li id="theme-3">
                        <i className="zmdi zmdi-check" />
                      </li>
                      <li id="theme-4">
                        <i className="zmdi zmdi-check" />
                      </li>
                      <li id="theme-5">
                        <i className="zmdi zmdi-check" />
                      </li>
                      <li id="theme-6">
                        <i className="zmdi zmdi-check" />
                      </li>
                    </ul>
                    <h6 className="mt-30 mb-15">Primary colors</h6>
                    <div className="radio mb-5">
                      <input
                        type="radio"
                        name="radio-primary-color"
                        id="pimary-color-green"
                        defaultValue="pimary-color-green"
                      />
                      <label htmlFor="pimary-color-green"> Green </label>
                    </div>
                    <div className="radio mb-5">
                      <input
                        type="radio"
                        name="radio-primary-color"
                        id="pimary-color-red"
                        defaultValue="pimary-color-red"
                      />
                      <label htmlFor="pimary-color-red"> Red </label>
                    </div>
                    <div className="radio mb-5">
                      <input
                        type="radio"
                        name="radio-primary-color"
                        id="pimary-color-blue"
                        defaultValue="pimary-color-blue"
                      />
                      <label htmlFor="pimary-color-blue"> Blue </label>
                    </div>
                    <div className="radio mb-5">
                      <input
                        type="radio"
                        name="radio-primary-color"
                        id="pimary-color-yellow"
                        defaultValue="pimary-color-yellow"
                      />
                      <label htmlFor="pimary-color-yellow"> Yellow </label>
                    </div>
                    <div className="radio mb-5">
                      <input
                        type="radio"
                        name="radio-primary-color"
                        id="pimary-color-pink"
                        defaultChecked
                        defaultValue="pimary-color-pink"
                      />
                      <label htmlFor="pimary-color-pink"> Pink </label>
                    </div>
                    <div className="radio mb-5">
                      <input
                        type="radio"
                        name="radio-primary-color"
                        id="pimary-color-orange"
                        defaultValue="pimary-color-orange"
                      />
                      <label htmlFor="pimary-color-orange"> Orange </label>
                    </div>
                    <div className="radio mb-5">
                      <input
                        type="radio"
                        name="radio-primary-color"
                        id="pimary-color-gold"
                        defaultValue="pimary-color-gold"
                      />
                      <label htmlFor="pimary-color-gold"> Gold </label>
                    </div>
                    <div className="radio mb-35">
                      <input
                        type="radio"
                        name="radio-primary-color"
                        id="pimary-color-silver"
                        defaultValue="pimary-color-silver"
                      />
                      <label htmlFor="pimary-color-silver"> Silver </label>
                    </div>
                    <button
                      id="reset_setting"
                      className="btn  btn-success btn-xs btn-outline btn-rounded mb-10"
                    >
                      reset
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <button
            id="setting_panel_btn"
            className="btn btn-success btn-circle setting-panel-btn shadow-2dp"
          >
            <i className="zmdi zmdi-settings" />
          </button>
          {/* /Right Setting Menu */}
        </div>
      </div>
    );
  }
}
