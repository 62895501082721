import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  getAllLeads,
  getAllEmployeeName,
  // fetchLeadItemByID,
  addLeadsNotes,
  updateLead,
  fetchLeadsNotes,
  // dispatchCourier,
  // confirmCourier,
} from "../../actions/index";
import Modal from "../Fixed/Modal";
// import ConfirmOrderForm from "./ConfirmOrderForm";
// import DispatchForm from "./DispatchForm";

class Leads extends React.Component {
  //state for ad dand edit button
  state = {
    pageNumber: 0,
    currentLeadID: "",
    currentID: "",
    note: "",
    accMgr: "",
    orderType: "",
    searchedName: "",
  };

  componentDidMount() {
    this.props.getAllLeads(this.state.pageNumber);
    this.props.getAllEmployeeName();
  }
  //Search Order
  SearchOrder = () => {
    const { pageNumber, accMgr, orderType, searchedName } = this.state;
    this.props.getAllOrders(pageNumber, accMgr, orderType, searchedName);
  };

  //OrderTypes

  orderType = [
    "New Order",
    "Due Payment",
    "Ready To Dispatch",
    "Dispatched",
    "All",
  ];

  renderOptionList = (array) => {
    return array.map((el) => {
      return (
        <option key={array.indexOf(el) + 1} value={el}>
          {el}
        </option>
      );
    });
  };

  orders = () => {
    if (
      this.props.orders.orders &&
      Object.values(this.props.orders.orders).length > 0
    ) {
      return Object.values(this.props.orders.orders);
    } else {
      return null;
    }
  };

  //Print Courier Status
  renderCards = () => {
    return (
      <div>
        <div className="row ml-2 mr-2">
          <div className="col-lg-3 col-6">
            {/* small card */}
            <div className="small-box bg-info">
              <div className="inner">
                <h3 style={{ color: "#fff" }}>
                  {/* {this.props.clients.numClients} */}
                  20
                </h3>

                <p style={{ color: "#fff" }}>Total Orders</p>
              </div>
              <div className="icon">
                <i className="fas fa-shopping-cart" />
              </div>
              <a href="fakeurl" className="small-box-footer">
                More info <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>
          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small card */}
            <div className="small-box bg-success">
              <div className="inner">
                <h3 style={{ color: "#fff" }}>
                  {/* {this.props.clients.clients
                    ? this.props.clients.clients.filter((el) => {
                        return el.ClientRole === "Dealer";
                      }).length
                    : "NA"} */}
                  00
                </h3>
                <p style={{ color: "#fff" }}>New Orders</p>
              </div>
              <div className="icon">
                <i className="fas fa-arrow-circle-right" />
              </div>
              <a href="fakeurl" className="small-box-footer">
                More info <i className="fa fa-arrow-circle-right" />
              </a>
            </div>
          </div>
          {/* ./col */}

          <div className="col-lg-3 col-6">
            {/* small card */}
            <div className="small-box bg-warning">
              <div className="inner">
                <h3 style={{ color: "#fff" }}>
                  {/* {this.props.clients.clients
                    ? this.props.clients.clients.filter((el) => {
                        return el.ClientRole === "User";
                      }).length
                    : "NA"} */}
                  00
                </h3>
                <p style={{ color: "#fff" }}>Payment Pending</p>
              </div>
              <div className="icon">
                <i className="fas fa-user-plus" />
              </div>
              <a
                href="fakeurl"
                className="small-box-footer"
                style={{ color: "#fff" }}
              >
                More info <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>
          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small card */}
            <div className="small-box bg-danger">
              <div className="inner">
                <h3 style={{ color: "#fff" }}>
                  {/* {this.props.clients.clients
                    ? this.props.clients.clients.filter((el) => {
                        return el.Status.toUpperCase() === "TRUE";
                      }).length
                    : "NA"} */}
                  000
                </h3>
                <p style={{ color: "#fff" }}>Dispatched</p>
              </div>
              <div className="icon">
                <i className="fas fa-chart-pie" />
              </div>
              <a href="fakeurl" className="small-box-footer">
                More info <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>
          {/* ./col */}
        </div>
      </div>
    );
  };

  //Pull Courier Items
  fetchCourierItems = (CDID) => {
    if (!this.props.orders[CDID]) {
      this.props.fetchOrderItemByCdid(CDID);
    }
  };

  //add notes
  addNotes = (note, CDID) => {
    if (!this.props.orders[CDID]) {
      this.props.addOrderNotes(note, CDID);
    }
  };

  // order push back
  pushBackClickHandler = (note, cdid) => {
    this.props.updateOrderStatus(note, cdid, "pushed_back");
  };

  // payment reverse
  reversePaymentClickHandler = (note, cdid) => {
    this.props.updateOrderStatus(note, cdid, "reverse_paymen");
  };

  // dispatch order
  dispatchCourierClickHandler = () => {
    if (this.props.form.dispatchForm && this.props.form.dispatchForm.values) {
      const {
        notes = "",
        address = "",
        dispatchType,
        courierVendor = "",
        courierCompanyName = "",
        trackingNum = "",
        courierCharges = "",
      } = this.props.form.dispatchForm.values;
      // console.log(
      //   notes,
      //   address,
      //   dispatchType,
      //   courierVendor,
      //   courierCompanyName,
      //   trackingNum,
      //   courierCharges
      // );
      this.props.dispatchCourier(
        notes,
        this.state.currentCDID,
        address,
        dispatchType,
        courierVendor,
        courierCompanyName,
        trackingNum,
        courierCharges
      );
    }
  };
  //close order

  // receive payment

  // confirm payment

  // confirm Courier

  confirmCourierClickHandler = () => {
    if (
      this.props.form.confirmCourierForm &&
      this.props.form.confirmCourierForm.values
    ) {
      const {
        follow_up_date = "",
        note = "",
        payment_mode = "",
        received_amt = "",
        acc_no = "",
        chq_date = "",
        bank_name = "",
        discount = "",
        chq_no = "",
        paytm_ref_no = "",
        due_amt = "",
        chq_status = "",
        chq_posted_to = "",
      } = this.props.form.confirmCourierForm.values;
      console.log(
        moment(follow_up_date, "YYYY-MM-DDTHH:mm", true).format("YYYY/MM/DD"),
        note,
        this.state.currentCDID,
        payment_mode,
        received_amt,
        acc_no,
        chq_date,
        bank_name,
        discount,
        chq_no,
        paytm_ref_no,
        due_amt,
        chq_status,
        chq_posted_to
      );
      // this.confirmCourier(
      //   follow_up_date,
      //   note,
      //   this.state.currentCDID,
      //   payment_mode,
      //   received_amt,
      //   acc_no,
      //   chq_date,
      //   bank_name,
      //   discount,
      //   chq_no,
      //   paytm_ref_no,
      //   due_amt,
      //   chq_status,
      //   chq_posted_to
      // );
    }
  };

  // get product table
  renderProductTable = (products) => {
    return products.map((el) => {
      return (
        <tr>
          <td>{products.indexOf(el) + 1} </td>
          <td>{el.model}</td>
          <td>{el.quantity}</td>
          <td>{el.rate}</td>
          <td>{el.taxAmt}</td>
          <td>{el.totalAmt}</td>
        </tr>
      );
    });
  };

  //render order Data for table
  renderOrderList = (orders) => {
    // console.log(orders);
    return orders
      .sort((a, b) => (a.position > b.position ? 1 : -1))
      .map((el) => {
        let bgColor = "";
        let borderColor = "";
        let icon = "";
        let txtColor = "";
        if (el.CurrentStatus === "New Order") {
          bgColor = "#F3DB74FF";
          borderColor = "#A13941FF";
          txtColor = "#A13941FF";
          icon = "fa fa-shopping-cart";
        } else if (el.CurrentStatus === "Ready To Dispatch") {
          borderColor = "rgb(0, 164, 204)";
          txtColor = "#00A4CCFF";
          icon = "fa fa-rocket";
        } else if (el.CurrentStatus === "Due Payment") {
          bgColor = "#C7D3D4FF";
          borderColor = "rgb(96, 63, 131)";
          txtColor = "#603F83FF";
          icon = "fa fa-credit-card";
        } else if (el.CurrentStatus === "Dispatched") {
          bgColor = "#6DAC4FFF";
          borderColor = "#6DAC4FFF";
          txtColor = "rgb(109, 172, 79)";
          icon = "fa fa-gift";
        }
        return (
          <Fragment>
            <tr>
              <td
                data-toggle="collapse"
                data-target={`#${el.CDID}`}
                aria-expanded="false"
                aria-controls={`${el.CDID}`}
                onClick={() => {
                  this.fetchCourierItems(el.CDID);
                }}
              >
                {/* <i className="fa fa-caret-right" /> */}
                {orders.indexOf(el) + this.state.pageNumber + 1}
              </td>
              <td
                data-toggle="collapse"
                data-target={`#${el.CDID}`}
                aria-expanded="false"
                aria-controls={`${el.CDID}`}
                onClick={() => {
                  this.fetchCourierItems(el.CDID);
                }}
              >
                {el.orderId}
              </td>
              <td
                data-toggle="collapse"
                data-target={`#${el.CDID}`}
                aria-expanded="false"
                aria-controls={`${el.CDID}`}
                onClick={() => {
                  this.fetchCourierItems(el.CDID);
                }}
              >
                {el.CompanyName}
              </td>
              <td
                data-toggle="collapse"
                data-target={`#${el.CDID}`}
                aria-expanded="false"
                aria-controls={`${el.CDID}`}
                onClick={() => {
                  this.fetchCourierItems(el.CDID);
                }}
              >
                {el.AccountManager}
              </td>
              <td
                data-toggle="collapse"
                data-target={`#${el.CDID}`}
                aria-expanded="false"
                aria-controls={`${el.CDID}`}
                onClick={() => {
                  this.fetchCourierItems(el.CDID);
                }}
              >
                {el.TAmount}
              </td>
              <td
                data-toggle="collapse"
                data-target={`#${el.CDID}`}
                aria-expanded="false"
                aria-controls={`${el.CDID}`}
                onClick={() => {
                  this.fetchCourierItems(el.CDID);
                }}
              >
                {el.BaAmt ? `${el.BaAmt}` : "0.00"}
              </td>
              <td
                data-toggle="collapse"
                data-target={`#${el.CDID}`}
                aria-expanded="false"
                aria-controls={`${el.CDID}`}
                onClick={() => {
                  this.fetchCourierItems(el.CDID);
                }}
              >
                {el.CourierAmt ? `${el.CourierAmt}.00` : "0.00"}
              </td>
              <td
                data-toggle="collapse"
                data-target={`#${el.CDID}`}
                aria-expanded="false"
                aria-controls={`${el.CDID}`}
                onClick={() => {
                  this.fetchCourierItems(el.CDID);
                }}
              >
                {" "}
                {`Added By ${el.AddedBy} at ${el.AddedDate}`}
              </td>
              <td>
                <a
                  type="button"
                  class="btn  btn-success btn-outline btn-rounded"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  data-whatever="@getbootstrap"
                  style={{
                    borderRadius: 21,
                    borderColor: borderColor,
                    color: txtColor,
                    fontWeight: 700,
                    pointerEvents: "none",
                  }}
                >
                  {el.CurrentStatus} &nbsp;{" "}
                  <i className={icon} style={{ color: txtColor }} />
                </a>
              </td>
              <td style={{ fontSize: 20 }}>
                <a
                  href="javascript:void(0)"
                  className="text-inverse pr-10"
                  type="button"
                  title
                  data-toggle="modal"
                  data-target="#Notes_add_model"
                  data-whatever="@mdo"
                  data-original-title="Add Note"
                  onClick={() => {
                    console.log(el.orderId, el.CDID);
                    this.setState({
                      note: "",
                      currentOrderID: el.orderId,
                      currentCDID: el.CDID,
                    });
                  }}
                >
                  {" "}
                  <i className="fa fa-plus-circle txt-primary" />
                </a>

                <a
                  data-toggle="modal"
                  data-target="#Notes_model"
                  data-whatever="@mdo"
                  className="text-inverse"
                  type="button"
                  data-original-title="Show Notes"
                  onClick={() => {
                    console.log(el.orderId, el.CDID);
                    this.setState({
                      currentOrderID: el.orderId,
                      currentCDID: el.CDID,
                    });
                  }}
                >
                  <i className="fa fa-comments txt-success" />
                </a>
              </td>
            </tr>

            <tr>
              <td colSpan={10} style={{ padding: 0, backgroundColor: "#fff" }}>
                <div
                  id={`${el.CDID}`}
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <div className="col-sm-7" style={{ overflow: "auto" }}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Model</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Tax</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.orders[el.CDID]
                            ? this.renderProductTable(
                                this.props.orders[el.CDID]
                              )
                            : ""}
                        </tbody>
                      </table>
                    </div>

                    <div
                      className="col-sm-5"
                      style={{
                        paddingBottom: 15,
                        overflow: "auto",
                        paddingTop: 40,
                        textAlign: "center",
                      }}
                    >
                      <div
                        className="col-sm-5"
                        style={{
                          textAlign: "left",
                          paddingLeft: "40px",
                        }}
                      >
                        <p>Shipping($)</p>
                        <p>Gross Amount ($)</p>
                        <p>Taxable Value($)</p>
                        <p>Cash Amount($)</p>
                        <p>GST@18%</p>
                        <p>Round Off</p>
                        <p>
                          <strong>Total($)</strong>
                        </p>
                      </div>
                      <div
                        className="col-sm-3"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <p>{`${
                          parseInt(el.CourierAmt) + parseInt(el.CourierTax)
                        }.00`}</p>
                        {this.props.orders[el.CDID] ? (
                          <Fragment>
                            <p>
                              {`${this.props.orders[el.CDID]
                                .map((e) => {
                                  return e.totalAmt;
                                })
                                .reduce((total, e) => {
                                  return total + parseInt(e);
                                }, 0)}.00`}
                            </p>
                            <p>{`${this.props.orders[el.CDID]
                              .map((e) => {
                                return e.taxAmt > 0 ? e.totalAmt : 0;
                              })
                              .reduce((total, e) => {
                                return total + parseInt(e);
                              }, 0)}.00`}</p>
                            <p>{`${this.props.orders[el.CDID]
                              .map((e) => {
                                return e.taxAmt == 0 ? e.totalAmt : 0;
                              })
                              .reduce((total, e) => {
                                return total + parseInt(e);
                              }, 0)}.00`}</p>
                            <p>{`${this.props.orders[el.CDID]
                              .map((e) => {
                                return e.taxAmt;
                              })
                              .reduce((total, e) => {
                                return total + parseInt(e);
                              }, 0)}.00`}</p>
                            <p>0.00</p>
                            <p>
                              <strong>{el.TAmount}</strong>
                            </p>
                          </Fragment>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-sm-12" style={{ paddingBottom: 10 }}>
                        <div className="button-list">
                          {this.props.orders.orders &&
                          this.props.orders.orders[el.CDID]
                            .showPaymentReceivedBtn ? (
                            <a
                              data-toggle="modal"
                              data-target="#orderpayment"
                              data-whatever="@mdo"
                              onClick={() => {
                                console.log(el.orderId, el.CDID);
                                this.setState({
                                  note: "",
                                  currentOrderID: el.orderId,
                                  currentCDID: el.CDID,
                                });
                              }}
                            >
                              <button
                                className="btn btn-default btn-icon-anim btn-circle"
                                data-toggle="tooltip"
                                data-original-title="Payment Received"
                              >
                                <i className="fa  fa-bank" />
                              </button>
                            </a>
                          ) : null}
                          {this.props.orders.orders &&
                          this.props.orders.orders[el.CDID]
                            .showRepeatOrderOption ? (
                            <a
                              data-toggle="modal"
                              data-target="#exampleModal2"
                              data-whatever="@mdo"
                              onClick={() => {
                                console.log(el.orderId, el.CDID);
                                this.setState({
                                  note: "",
                                  currentOrderID: el.orderId,
                                  currentCDID: el.CDID,
                                });
                              }}
                            >
                              <button
                                className="btn btn-primary btn-icon-anim btn-circle"
                                data-toggle="tooltip"
                                data-original-title="Repeat Order"
                              >
                                <i className="fa fa-copy" />
                              </button>
                            </a>
                          ) : null}
                          {this.props.orders.orders &&
                          this.props.orders.orders[el.CDID].showPushBackBtn ? (
                            <a
                              data-toggle="modal"
                              data-target="#Push_Back_model"
                              data-whatever="@mdo"
                              onClick={() => {
                                console.log(el.orderId, el.CDID);
                                this.setState({
                                  note: "",
                                  currentOrderID: el.orderId,
                                  currentCDID: el.CDID,
                                });
                              }}
                            >
                              <button
                                className="btn btn-danger btn-icon-anim btn-circle"
                                data-toggle="tooltip"
                                data-original-title="Order Push Back"
                              >
                                <i className="fa fa-reply" />
                              </button>
                            </a>
                          ) : null}
                          {this.props.orders.orders &&
                          this.props.orders.orders[el.CDID]
                            .showReversePaymentOption ? (
                            <a
                              data-toggle="modal"
                              data-target="#Reverse_Payment_model"
                              data-whatever="@mdo"
                              onClick={() => {
                                console.log(el.orderId, el.CDID);
                                this.setState({
                                  note: "",
                                  currentOrderID: el.orderId,
                                  currentCDID: el.CDID,
                                });
                              }}
                            >
                              <button
                                className="btn btn-success btn-icon-anim btn-circle"
                                data-toggle="tooltip"
                                data-original-title="Reverse Payment"
                              >
                                <i className="fa fa-reply-all" />
                              </button>
                            </a>
                          ) : null}

                          {this.props.orders.orders &&
                          this.props.orders.orders[el.CDID]
                            .showWaveOffOption ? (
                            <a
                              data-toggle="modal"
                              data-target="#wave_off_model"
                              data-whatever="@mdo"
                              onClick={() => {
                                console.log(el.orderId, el.CDID);
                                this.setState({
                                  note: "",
                                  currentOrderID: el.orderId,
                                  currentCDID: el.CDID,
                                });
                              }}
                            >
                              <button
                                className="btn btn-info btn-icon-anim btn-circle"
                                data-toggle="tooltip"
                                data-original-title="Wave off"
                              >
                                <i className="fa fa-scissors" />
                              </button>
                            </a>
                          ) : null}
                          {this.props.orders.orders &&
                          this.props.orders.orders[el.CDID]
                            .confirmOrderBtnText ? (
                            <a
                              data-toggle="modal"
                              data-target="#confirmOrder"
                              data-whatever="@mdo"
                              onClick={() => {
                                console.log(el.orderId, el.CDID);
                                this.setState({
                                  note: "",
                                  currentOrderID: el.orderId,
                                  currentCDID: el.CDID,
                                });
                              }}
                            >
                              <button
                                className="btn btn-primary btn-icon-anim btn-circle"
                                data-toggle="tooltip"
                                data-original-title={
                                  this.props.orders.orders[el.CDID]
                                    .confirmOrderBtnText
                                }
                              >
                                <i className="fa fa-flash" />
                              </button>
                            </a>
                          ) : null}
                          {this.props.orders.orders &&
                          this.props.orders.orders[el.CDID]
                            .dispatchOrderBtnText ? (
                            <a
                              data-toggle="modal"
                              data-target="#dispatchmodel"
                              data-whatever="@mdo"
                              onClick={() => {
                                console.log(el.orderId, el.CDID);
                                this.setState({
                                  note: "",
                                  currentOrderID: el.orderId,
                                  currentCDID: el.CDID,
                                });
                              }}
                            >
                              <button
                                className="btn btn-warning btn-icon-anim btn-circle"
                                data-toggle="tooltip"
                                data-original-title="Dispatch"
                              >
                                <i className="icon-rocket" />
                              </button>
                            </a>
                          ) : null}
                          {
                            <Link
                              // data-toggle="modal"
                              // data-target="#Assign_Device_model"
                              // data-whatever="@mdo"
                              onClick={() => {
                                console.log(el.orderId, el.CDID);
                                this.setState({
                                  currentOrderID: el.orderId,
                                  currentCDID: el.CDID,
                                });
                              }}
                              to={`/devices/assign/${el.CDID}`}
                            >
                              <button
                                className="btn btn-danger btn-icon-anim btn-circle"
                                data-toggle="tooltip"
                                data-original-title="Assign Device Back"
                              >
                                <i className="fa fa-plus" />
                              </button>
                            </Link>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </Fragment>
        );
      });
  };

  //renderNotes
  renderNotes = (notes) => {
    return notes.map((el) => {
      return (
        <div className="timeline-item align-items-start">
          {/*begin::Label*/}
          <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
            {el.date}
          </div>
          {/*end::Label*/}
          {/*begin::Badge*/}
          <div className="timeline-badge">
            <i className="fa fa-genderless text-warning icon-xl" />
          </div>
          {/*end::Badge*/}
          {/*begin::Text*/}
          <div className="font-weight-mormal font-size-lg timeline-content text-muted pl-3">
            {el.comment}
            <p
              style={{
                color: "rgb(161 161 163)",
                fontSize: 11,
                fontWeight: 500,
              }}
            >
              <i className="fa fa-user" /> {el.name}
            </p>
          </div>
          {/*end::Text*/}
        </div>
      );
    });
  };

  //Show Notes Button Handler
  showNotesButtonHandler = (notes) => {
    return this.renderNotes(notes);
  };

  //Next Button Handler
  nextButtonHandler = () => {
    const { pageNumber, accMgr, orderType, searchedName } = this.state;
    // if (this.props.devices.totalDevices >= this.state.pageNumber) {
    this.props.getAllLeads(pageNumber + 10, accMgr, orderType, searchedName);
    let newPageNumber = this.state.pageNumber + 10;
    this.setState({
      pageNumber: newPageNumber,
      currentLeadID: "",
      currentID: "",
    });
    // }
  };

  //Previous Button Handler
  perviousButtonHandler = () => {
    const { pageNumber, accMgr, orderType, searchedName } = this.state;
    if (pageNumber > 0) {
      this.props.getAllLeads(pageNumber - 10, accMgr, orderType, searchedName);
      let newPageNumber = this.state.pageNumber - 10;
      this.setState({
        pageNumber: newPageNumber,
        currentLeadID: "",
        currentID: "",
      });
    }
  };

  //cards

  //main render function
  render() {
    console.log(this.state);
    return (
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* Title */}
          <div className="row heading-bg">
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <h5 className="txt-dark">Orders Details</h5>
            </div>
            {/* Breadcrumb */}
            <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
              <ol className="breadcrumb">
                <li>
                  <Link
                    to="/"
                    onClick={() => {
                      setTimeout(() => {
                        window.location.reload(true);
                      }, 50);
                    }}
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="active">
                  <span>Orders List</span>
                </li>
              </ol>
            </div>
            {/* /Breadcrumb */}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <section className="content">
                {this.renderCards()}
                <div className="flex-row-fluid ml-lg-8">
                  {/*begin::Card*/}
                  <div className="card card-custom">
                    {/*begin::Header*/}
                    <div
                      className="card-header flex-wrap border-0 pt-6 pb-0"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder font-size-h3 text-dark">
                          My Orders
                        </span>
                        <p>Manage over 1600 products</p>
                      </h3>
                      <div className="card-toolbar" style={{ float: "right" }}>
                        <a
                          href="fakeurl"
                          className="btn btn-primary font-weight-bolder"
                        >
                          <span className="svg-icon svg-icon-md">
                            {/*begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Design/Flatten.svg*/}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x={0} y={0} width={24} height={24} />
                                <circle fill="#000000" cx={9} cy={15} r={6} />
                                <path
                                  d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                              </g>
                            </svg>
                            {/*end::Svg Icon*/}
                          </span>
                          Place New Order
                        </a>
                        {/*end::Button*/}
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body">
                      {/*begin: Search Form*/}
                      {/*begin::Search Form*/}
                      <div className="mb-10">
                        <div className="row align-items-center">
                          <div className="col-lg-9 col-xl-8">
                            <div className="row align-items-center">
                              <div className="col-md-4 my-2 my-md-0">
                                <div className="input-icon">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    value={this.state.searchedName}
                                    onChange={(event) => {
                                      this.setState({
                                        pageNumber: 0,
                                        searchedName: event.target.value,
                                      });
                                    }}
                                  />
                                  <span>
                                    <i className="flaticon2-search-1 text-muted" />
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-4 my-2 my-md-0">
                                <select
                                  className="form-control"
                                  id="kt_datatable_search_status"
                                  tabIndex="null"
                                  name="accMgr"
                                  value={this.state.accMgr}
                                  onChange={(event) => {
                                    this.setState({
                                      pageNumber: 0,
                                      accMgr: event.target.value,
                                    });
                                  }}
                                >
                                  <option value="">Account Manager</option>
                                  {!this.props.employee.employeeName
                                    ? "No Employee"
                                    : this.renderOptionList(
                                        this.props.employee.employeeName
                                      )}
                                </select>
                              </div>
                              <div className="col-md-4 my-2 my-md-0">
                                <select
                                  className="form-control"
                                  id="kt_datatable_search_type"
                                  name="orderType"
                                  tabIndex="null"
                                  value={this.state.orderType}
                                  onChange={(event) => {
                                    this.setState({
                                      pageNumber: 0,
                                      orderType: event.target.value,
                                    });
                                  }}
                                >
                                  <option>Status</option>
                                  {this.renderOptionList(this.orderType)}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 col-xl-4 mt-5 mt-lg-0"
                            onClick={this.SearchOrder}
                          >
                            <a href="fakeurl" className="btn btn-primary">
                              Search
                            </a>
                          </div>
                        </div>
                      </div>
                      {/*end::Search Form*/}
                      {/*end: Search Form*/}
                      {/*begin: Datatable*/}
                      <div
                        className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded"
                        id="kt_datatable"
                        style={{}}
                      >
                        <table className="table table-hover display  pb-30">
                          <thead>
                            <tr>
                              <th># </th>
                              <th>LeadID</th>
                              <th>Company Name</th>
                              <th>Account Manager</th>
                              <th>Amount</th>
                              <th>Balance Amount</th>
                              <th>Shipping Cost</th>
                              <th>Added</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.orders.orders &&
                            Object.values(this.props.orders.orders).length > 0
                              ? this.renderOrderList(this.orders())
                              : ""}
                          </tbody>
                        </table>
                      </div>
                      {/* Notes Model Start */}
                      <Modal
                        header={`Notes#${this.state.currentOrderID}`}
                        id="Notes_model"
                      >
                        {/*begin::Timeline*/}
                        <div className="timeline timeline-6 mt-3">
                          {/*begin::Item*/}
                          {this.props.orders.orders &&
                          this.state.currentCDID &&
                          this.props.orders.orders[this.state.currentCDID]
                            ? this.renderNotes(
                                this.props.orders.orders[this.state.currentCDID]
                                  .Notes
                              )
                            : null}
                          {/*end::Item*/}
                        </div>
                        {/*end::Timeline*/}
                      </Modal>
                      {/* add notes model */}
                      <Modal
                        header={`Add_Notes#${this.state.currentOrderID}`}
                        id="Notes_add_model"
                      >
                        <div class="modal-body">
                          <form>
                            <div class="form-group">
                              <label
                                for="message-text"
                                class="control-label mb-10"
                              >
                                Message:
                              </label>
                              <textarea
                                class="form-control"
                                id="message-text1"
                                name="note"
                                value={this.state.note}
                                onChange={(event) =>
                                  this.setState({
                                    note: event.target.value,
                                  })
                                }
                              ></textarea>
                            </div>
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button
                            class="btn btn-warning btn-rounded btn-lable-wrap right-label"
                            data-dismiss="modal"
                          >
                            <span class="btn-text">Close</span>{" "}
                            <span class="btn-label">
                              <i class="fa fa-times"></i>{" "}
                            </span>
                          </button>

                          <button
                            class="btn btn-primary btn-rounded btn-icon left-icon"
                            onClick={() =>
                              this.addNotes(
                                this.state.note,
                                this.state.currentCDID
                              )
                            }
                          >
                            <span>Add Notes</span> <i class="fa fa-check"></i>{" "}
                          </button>
                        </div>
                      </Modal>
                      {/*Push Back Order model */}
                      <Modal
                        header={`Push Back Order#${this.state.currentOrderID}`}
                        id="Push_Back_model"
                      >
                        <div class="modal-body">
                          <form>
                            <div class="form-group">
                              <label
                                for="message-text"
                                class="control-label mb-10"
                              >
                                Message:
                              </label>
                              <textarea
                                class="form-control"
                                id="message-text1"
                                value={this.state.note}
                                onChange={(event) =>
                                  this.setState({
                                    note: event.target.value,
                                  })
                                }
                              ></textarea>
                            </div>
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button
                            class="btn btn-warning btn-rounded btn-lable-wrap right-label"
                            data-dismiss="modal"
                          >
                            <span class="btn-text">Close</span>{" "}
                            <span class="btn-label">
                              <i class="fa fa-times"></i>{" "}
                            </span>
                          </button>

                          <button
                            class="btn btn-primary btn-rounded btn-icon left-icon"
                            onClick={() =>
                              this.pushBackClickHandler(
                                this.state.note,
                                this.state.currentCDID
                              )
                            }
                          >
                            <span>Confirm</span> <i class="fa fa-check"></i>{" "}
                          </button>
                        </div>
                      </Modal>
                      {/* Wave off model */}
                      <Modal
                        header={`Wave Off Order#${this.state.currentOrderID}`}
                        id="wave_off_model"
                      >
                        <div class="modal-body">
                          <form>
                            <div class="form-group">
                              <label
                                for="message-text"
                                class="control-label mb-10"
                              >
                                Message:
                              </label>
                              <textarea
                                class="form-control"
                                id="message-text1"
                              ></textarea>
                            </div>
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button
                            class="btn btn-warning btn-rounded btn-lable-wrap right-label"
                            data-dismiss="modal"
                          >
                            <span class="btn-text">Close</span>{" "}
                            <span class="btn-label">
                              <i class="fa fa-times"></i>{" "}
                            </span>
                          </button>

                          <button class="btn btn-primary btn-rounded btn-icon left-icon">
                            <span>Confirm</span> <i class="fa fa-check"></i>{" "}
                          </button>
                        </div>
                      </Modal>
                      {/* Reverse Payment model */}
                      <Modal
                        header={`Reverse Payment#${this.state.currentOrderID}`}
                        id="Reverse_Payment_model"
                      >
                        <div class="modal-body">
                          <form>
                            <div class="form-group">
                              <label
                                for="message-text"
                                class="control-label mb-10"
                              >
                                Message:
                              </label>
                              <textarea
                                class="form-control"
                                id="message-text1"
                                value={this.state.note}
                                onChange={(event) =>
                                  this.setState({
                                    note: event.target.value,
                                  })
                                }
                              ></textarea>
                            </div>
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button
                            class="btn btn-warning btn-rounded btn-lable-wrap right-label"
                            data-dismiss="modal"
                          >
                            <span class="btn-text">Close</span>{" "}
                            <span class="btn-label">
                              <i class="fa fa-times"></i>{" "}
                            </span>
                          </button>

                          <button
                            class="btn btn-primary btn-rounded btn-icon left-icon"
                            onClick={() =>
                              this.reversePaymentClickHandler(
                                this.state.note,
                                this.state.currentCDID
                              )
                            }
                          >
                            <span>confirm</span> <i class="fa fa-check"></i>{" "}
                          </button>
                        </div>
                      </Modal>
                      {/* dispatch Model */}
                      <Modal
                        header={`Dispatch Order#${this.state.currentOrderID}`}
                        id="dispatchmodel"
                      >
                        <div class="modal-body">{/* <DispatchForm /> */}</div>
                        <div class="modal-footer">
                          <button
                            class="btn btn-warning btn-rounded btn-lable-wrap right-label"
                            data-dismiss="modal"
                          >
                            <span class="btn-text">Close</span>{" "}
                            <span class="btn-label">
                              <i class="fa fa-times"></i>{" "}
                            </span>
                          </button>

                          <button
                            class="btn btn-primary btn-rounded btn-icon left-icon"
                            onClick={this.dispatchCourierClickHandler}
                          >
                            <span>Dispatch</span> <i class="fa fa-check"></i>{" "}
                          </button>
                        </div>
                      </Modal>
                      {/*end: Datatable*/}
                      {/* Confirm Courier Model */}
                      <Modal
                        header={`Confirm Courier#${this.state.currentOrderID}`}
                        id="confirmOrder"
                      >
                        <div class="modal-body">
                          {/* <ConfirmOrderForm
                            cdid={this.state.currentCDID}
                            orders={this.props.orders}
                            // initialValues={{ due_amt: "12345" }}
                          /> */}
                        </div>
                        <div class="modal-footer">
                          <button
                            class="btn btn-warning btn-rounded btn-lable-wrap right-label"
                            data-dismiss="modal"
                          >
                            <span class="btn-text">Close</span>{" "}
                            <span class="btn-label">
                              <i class="fa fa-times"></i>{" "}
                            </span>
                          </button>

                          <button
                            class="btn btn-primary btn-rounded btn-icon left-icon"
                            onClick={this.confirmCourierClickHandler}
                          >
                            <span>Confirm</span> <i class="fa fa-check"></i>{" "}
                          </button>
                        </div>
                      </Modal>
                      {/*end: Datatable */}
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "right", marginTop: "20px" }}
                      >
                        <button
                          className="btn btn-success"
                          name="previous"
                          type="button"
                          style={{ marginRight: "20px" }}
                          onClick={this.perviousButtonHandler}
                        >
                          Previous
                        </button>
                        <button
                          className="btn btn-success"
                          name="next"
                          type="button"
                          onClick={this.nextButtonHandler}
                        >
                          Next
                        </button>
                      </div>
                    </div>

                    {/*end::Body*/}
                  </div>
                  {/*end::Card*/}
                </div>
              </section>
            </div>
          </div>
          {/* Footer */}
          <footer className="footer container-fluid pl-30 pr-30">
            <div className="row">
              <div className="col-sm-12">
                <p>2017 © Libicon. Pampered by Libi Technologies</p>
              </div>
            </div>
          </footer>
          {/* /Footer */}
        </div>
      </div>
    );
  }
}

//Value pick form redux store to props
const mapStateToProp = (state) => {
  console.log(state.order);
  return {
    employee: state.employee,
    orders: state.order,
    form: state.form,
  };
};

//Connect with redux form with local redux store
export default connect(mapStateToProp, {
  getAllLeads,
  getAllEmployeeName,
  // fetchLeadItemByID,
  addLeadsNotes,
  updateLead,
  fetchLeadsNotes,
  // dispatchCourier,
  // confirmCourier,
})(Leads);
