import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
  getAllEmployeeName,
  checkIMEIExistence,
  fetchAllBatchNumber,
  getAllVendor,
  addSim,
} from "../../actions/index";

class AddSim extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    data: [],
    simNumArr: [],
    mobNumArr: [],
    simCompany: [],
    value: "",
    typingTimeout: 0,
  };

  componentDidMount() {
    this.props.getAllEmployeeName();
    this.props.fetchAllBatchNumber();
    this.props.getAllVendor();
  }

  componentDidUpdate() {
    const node = this.myRef.current;
    node.value = "";
  }

  //Ajax request to add sim on server
  addSim = () => {
    this.props.addSim(this.state.data);
    // this.props.checkIMEIExistence(this.state.devices);
  };

  //add sim to state from barcode scanner or manual type
  addSimToState = (e) => {
    e.preventDefault();
    let mobNum = e.target.value.trim();
    let simNum = this.props.formValues.values.simNum.trim();
    let mobNumArray = mobNum.split("\n");
    let simNumArray = simNum.split("\n");
    console.log(mobNumArray, simNumArray);

    //combine mobile and sim number [[sim,mob],[sim,mob]]
    var array2 = [];
    for (var i in mobNumArray) {
      array2.push([simNumArray[i], mobNumArray[i]]);
    }

    console.log(array2);
    if (this.props.formValues.values) {
      console.log(this.props.formValues.values);
    }

    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.state.typingTimeout = setTimeout(() => {
      let newData = this.state.data;
      let newMobNumArr = this.state.mobNumArr;
      let newSimNumArr = this.state.simNumArr;
      let newSimCompany = this.state.simCompany;
      array2.forEach((e) => {
        let { batchId, simCompany } = this.props.formValues.values;
        const data = {
          batchId,
          simCompany,
          simNo: e[0],
          mobileNo: e[1],
        };
        console.log(data);
        // const simCompany = this.props.formValues.values.simCompany;
        if (
          mobNumArray.length === simNumArray.length &&
          e[1].length > 0 &&
          e[1].length <= 13 &&
          !this.state.mobNumArr.includes(e[1]) &&
          e[0].length > 0 &&
          e[0].length <= 21 &&
          !this.state.simNumArr.includes(e[0])
        ) {
          newData.push(data);
          newMobNumArr.push(e[1]);
          newSimNumArr.push(e[0]);
          newSimCompany.push(simCompany);
        }
      });
      this.setState({
        mobNumArr: newMobNumArr,
        simNumArr: newSimNumArr,
        data: newData,
        simCompany: newSimCompany,
      });
    }, 100);
  };

  //Remove data from state
  removeDataFromState = (simNo) => {
    // console.log(imei);
    let newData = this.state.data;
    let newMobNumArr = this.state.mobNumArr;
    let newSimNumArr = this.state.simNumArr;

    var index = newSimNumArr.indexOf(simNo);
    // console.log(index);
    if (index !== -1) {
      newMobNumArr.splice(index, 1);
      newData.splice(index, 1);
      newSimNumArr.splice(index, 1);
    }
    console.log(newData, newSimNumArr, newMobNumArr);
    this.setState({
      data: newData,
      mobNumArr: newMobNumArr,
      simNumArr: newSimNumArr,
    });
  };

  //render sim from state to screen
  renderSimList = () => {
    console.log(this.state.data.length);
    if (this.state.data.length > 0) {
      return this.state.data.map((el) => {
        return (
          <tr>
            <td>{el.batchId}</td>
            <td>{el.simCompany}</td>
            <td>{el.simNo}</td>
            <td>{el.mobileNo}</td>
            <td>
              <a
                href="javascript:void(0)"
                className="text-inverse"
                title
                data-toggle="tooltip"
                data-original-title="Delete"
                onClick={() => this.removeDataFromState(el.simNo)}
              >
                <i className="zmdi zmdi-close txt-danger" />
              </a>
            </td>
          </tr>
        );
      });
    }
  };

  //Render Options in select tag

  renderOptionList = (array) => {
    return array.map((el) => {
      return (
        <option key={array.indexOf(el) + 1} value={el}>
          {el}
        </option>
      );
    });
  };

  //Device Location

  Location = ["Employee", "Dealer", "Vender", "User", "Office"];

  //Sim Company

  simCompany = ["Airtel", "Vodafone", "Idea", "BSNL", "MTNL", "Jio"];

  //Device Status

  Status = [
    "New",
    "Replaced",
    "Installed",
    "Under Repair",
    "Sold",
    "Repaired",
    "Void",
    "Other",
  ];

  //Render Error Message
  renderErrorMessage = (meta) => {
    if (meta.touched) {
      return <div className="form__error">{meta.error}</div>;
    } else {
      return null;
    }
  };

  //render Products count

  renderCount = () => {
    console.log(this.state.simCompany);
    const simCompany = this.state.simCompany;
    var uniqueSimCompany = simCompany.filter((v, i, a) => a.indexOf(v) === i);

    {
      return uniqueSimCompany.map((e) => {
        return (
          <div>
            {e}:&nbsp;{simCompany.filter((x) => x === e).length}
          </div>
        );
      });
    }
  };

  //convert input to redux field
  renderInput = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <input
          className="form-control"
          type={formProps.type}
          placeholder={formProps.placeholder}
          {...formProps.input}
        />
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };
  //convert select to redux field

  renderSelectTag = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <select
          className="form-control"
          type={formProps.type}
          {...formProps.input}
        >
          {formProps.children}
        </select>
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };

  // onSubmit = (e) => {
  //   e.preventDefault();
  // };

  renderFormFields = () => {
    const { handleSubmit, pristine, submitting } = this.props;
    return (
      <div className="form-wrap">
        <div className="form-horizontal">
          <div className="form-body">
            <h6 className="txt-dark capitalize-font">
              <i className="zmdi zmdi-account mr-10" />
              Sim Add Info
            </h6>

            <hr className="light-grey-hr" />
            <div className="row">
              <div
                className="col-sm-12"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "20px",
                }}
              >
                {this.renderCount()}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="panel panel-default card-view">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div className="table-wrap">
                        <div className="table-responsive">
                          <table className="table table-hover display  pb-30">
                            <thead>
                              <tr>
                                <th>Batch#</th>
                                <th>Company Name</th>
                                <th>Sim Number (ICCID)</th>
                                <th>Mobile number (MSIDN)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Field
                                    component="select"
                                    className="form-control"
                                    name="batchId"
                                    tabIndex={1}
                                  >
                                    <option value="">Select Batch </option>
                                    {!this.props.batch.batchNumber
                                      ? "No Batch"
                                      : this.renderOptionList(
                                          this.props.batch.batchNumber
                                        )}
                                  </Field>
                                </td>
                                <td>
                                  <Field
                                    component="select"
                                    className="form-control"
                                    name="simCompany"
                                    tabIndex={1}
                                  >
                                    <option value="">Select Sim Company</option>
                                    {this.renderOptionList(this.simCompany)}
                                  </Field>
                                </td>

                                <td>
                                  <Field
                                    name="simNum"
                                    ref={this.myRef}
                                    className="form-control"
                                    component="textarea"
                                    // onChange={(e) => {
                                    //   return this.addSumNumToState(e);
                                    // }}
                                    placeholder="eg.8991102005303645772U"
                                    disabled={
                                      !(
                                        this.props.formValues &&
                                        this.props.formValues.values
                                          .simCompany &&
                                        this.props.formValues.values.batchId
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <textarea
                                    name="mobNum"
                                    ref={this.myRef}
                                    className="form-control"
                                    onChange={(e) => {
                                      return this.addSimToState(e);
                                    }}
                                    placeholder="eg.8600020132565"
                                    disabled={
                                      !(
                                        this.props.formValues &&
                                        this.props.formValues.values
                                          .simCompany &&
                                        this.props.formValues.values.simNum &&
                                        this.props.formValues.values.batchId
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="seprator-block" />
          <h6 className="txt-dark capitalize-font">
            <i className="zmdi zmdi-account-box mr-10" />
            Sim List
          </h6>
          <hr className="light-grey-hr" />
          <div className="row">
            <div className="col-sm-12">
              <div className="panel panel-default card-view">
                <div className="panel-wrapper collapse in">
                  <div className="panel-body">
                    <div className="table-wrap">
                      <div className="table-responsive">
                        <table className="table table-hover display  pb-30">
                          <thead>
                            <tr>
                              <th>Batch#</th>
                              <th>simCompany</th>

                              <th>Sim Number (ICCID)</th>
                              <th>Mobile number (MSIDN)</th>
                            </tr>
                          </thead>
                          <tbody>{this.renderSimList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-actions mt-10">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-offset-3 col-md-9">
                    <button
                      type="button"
                      className="btn btn-success  mr-10"
                      onClick={this.addSim}
                    >
                      Submit
                    </button>
                    <button type="button" className="btn btn-default">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6"> </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div id="profile_15" className="tab-pane fade" role="tabpanel">
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default card-view">
              <div className="panel-heading">
                <div className="pull-left">
                  <h6 className="panel-title txt-dark">Add Sim Details</h6>
                </div>
                <div className="clearfix" />
              </div>
              <div className="panel-wrapper collapse in">
                <div className="panel-body">
                  <div className="row">
                    <div className="col-md-12">{this.renderFormFields()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//Value pick form redux store to props

const mapStateToProp = (state) => {
  return {
    employee: state.employee,
    batch: state.batch,
    vendor: state.vendor,
    formValues: state.form.SimForm,
  };
};

//Client Side Form validation
const validate = (formValues) => {
  const errors = {};
  // var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!formValues.vendor) {
    errors.vendor = "You must enter vendor name";
  }
  if (!formValues.batchNo) {
    errors.batchNo = "You must enter Batch Number";
  }

  if (!formValues.simCompany) {
    errors.simCompany = "You must enter simCompany";
  }
  if (!formValues.location) {
    errors.location = "You must enter Location";
  }
  if (!formValues.locationName) {
    errors.locationName = "You must enter Location Name";
  }
  if (!formValues.status) {
    errors.status = "You must enter device status";
  }
  return errors;
};

//Connect with redux form with local redux store
export default connect(mapStateToProp, {
  getAllEmployeeName,
  fetchAllBatchNumber,
  checkIMEIExistence,
  getAllVendor,
  addSim,
})(
  reduxForm({
    form: "SimForm",
    validate,
    initialValues: {
      location: "Office",
      locationName: "Company",
      status: "New",
    },
  })(AddSim)
);
