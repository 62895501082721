import React from "react";
import UpdateSim from "./UpdateSim";
import UpdateDevice from "./UpdateDevice";
import { connect } from "react-redux";

import {
  getAllEmployeeName,
  checkIMEIExistence,
  fetchAllOrderNumber,
  updateDevice,
  getAllDeviceModelName,
  fetchOrderByCdid,
  fetchOrderItemByCdid,
} from "../../actions/index";

class UpdateInventory extends React.Component {
  state = { orderId: "" };
  componentDidMount() {
    console.log(this.props.match);
    !this.props.match && this.props.getAllEmployeeName();
    !this.props.match && this.props.fetchAllOrderNumber();
    this.props.match && this.props.fetchOrderByCdid(this.props.match.params.id);
    this.props.match &&
      this.setState({ orderId: `LIBIO${this.props.match.params.id}` });
    this.props.match &&
      this.props.fetchOrderItemByCdid(this.props.match.params.id);
  }
  render() {
    return (
      <div>
        <div className="page-wrapper">
          <div className="container-fluid">
            {/* Title */}
            <div className="row heading-bg">
              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h5 className="txt-dark">Inventory Details</h5>
              </div>
              {/* Breadcrumb */}
              <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <ol className="breadcrumb">
                  <li>
                    <a href="index-2.html">Dashboard</a>
                  </li>
                  <li className="active">
                    <span>Assign Inventory</span>
                  </li>
                </ol>
              </div>
              {/* /Breadcrumb */}
            </div>
            <div className="tab-struct custom-tab-2 ">
              <ul role="tablist" className="nav nav-tabs" id="myTabs_15">
                <li
                  className="active"
                  role="presentation"
                  to="/inventory/devices/add"
                >
                  <a
                    aria-expanded="true"
                    data-toggle="tab"
                    role="tab"
                    id="home_tab_15"
                    href="#home_15"
                  >
                    Device
                  </a>
                </li>
                <li role="presentation" className to="/inventory/sims/add">
                  <a
                    data-toggle="tab"
                    id="profile_tab_15"
                    role="tab"
                    href="#profile_15"
                    aria-expanded="false"
                  >
                    Sim
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent_15">
                <UpdateDevice orderId={this.props.match.params.id} />
                {/* /Title */}
                <UpdateSim orderId={this.props.match.params.id} />
              </div>
            </div>
            <footer className="footer container-fluid pl-30 pr-30">
              <div className="row">
                <div className="col-sm-12">
                  <p>2017 © Libicon. Pampered by Libi Technologies</p>
                </div>
              </div>
            </footer>
            {/* /Footer */}
          </div>
        </div>
      </div>
    );
  }
}
//Value pick form redux store to props

const mapStateToProp = (state) => {
  return {
    // employee: state.employee,
    // order: state.order,
    // client: state.client,
    // deviceModel: state.device,
    // // formValues: state.form.DeviceForm,
    // // initialValues: {
    //   orderNo:
    //     (state.order.currentOrder && state.order.currentOrder.orderId) || "",
    //   location: (state.order.currentOrder && "Dealer") || "",
    //   status: "New",
    //   locationName:
    //     (state.order.currentOrder &&
    //       state.order.currentOrder.CompanyName.split("/")[1]) ||
    //     "",
    // },
  };
};

//Connect with redux form with local redux store
export default connect(mapStateToProp, {
  getAllEmployeeName,
  fetchAllOrderNumber,
  fetchOrderByCdid,
  checkIMEIExistence,
  updateDevice,
  getAllDeviceModelName,
  fetchOrderItemByCdid,
})(UpdateInventory);
