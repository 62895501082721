import * as TYPES from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.FETCH_ALL_SIM:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        sims: action.payload,
        totalSims: action.payload.totalSim,
      };
    case TYPES.FETCH_SIM_BY_SEARCH:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        sims: action.payload,
      };

    default:
      return state;
  }
};
