import React, { Component } from "react";
// import history from "../history";

export default class Dashboard extends Component {
  componentDidMount() {
    // window.location.reload(true);
  }

  render() {
    return (
      <div>
        {/* Main Content */}
        <div className="page-wrapper">
          <div className="container-fluid pt-25">
            <h1>Welcome to Libicon</h1>
            {/* Row
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="panel panel-default card-view pa-0">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body pa-0">
                      <div className="sm-data-box">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-xs-6 text-center pl-0 pr-0 data-wrap-left">
                              <span className="txt-dark block counter">
                                <span className="counter-anim">914,001</span>
                              </span>
                              <span className="weight-500 uppercase-font block font-13">
                                visits
                              </span>
                            </div>
                            <div className="col-xs-6 text-center  pl-0 pr-0 data-wrap-right">
                              <i className="icon-user-following data-right-rep-icon txt-light-grey" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="panel panel-default card-view pa-0">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body pa-0">
                      <div className="sm-data-box">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-xs-6 text-center pl-0 pr-0 data-wrap-left">
                              <span className="txt-dark block counter">
                                <span className="counter-anim">46.41</span>%
                              </span>
                              <span className="weight-500 uppercase-font block">
                                bounce rate
                              </span>
                            </div>
                            <div className="col-xs-6 text-center  pl-0 pr-0 data-wrap-right">
                              <i className="icon-control-rewind data-right-rep-icon txt-light-grey" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="panel panel-default card-view pa-0">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body pa-0">
                      <div className="sm-data-box">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-xs-6 text-center pl-0 pr-0 data-wrap-left">
                              <span className="txt-dark block counter">
                                <span className="counter-anim">4,054,876</span>
                              </span>
                              <span className="weight-500 uppercase-font block">
                                pageviews
                              </span>
                            </div>
                            <div className="col-xs-6 text-center  pl-0 pr-0 data-wrap-right">
                              <i className="icon-layers data-right-rep-icon txt-light-grey" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="panel panel-default card-view pa-0">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body pa-0">
                      <div className="sm-data-box">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-xs-6 text-center pl-0 pr-0 data-wrap-left">
                              <span className="txt-dark block counter">
                                <span className="counter-anim">46.43</span>%
                              </span>
                              <span className="weight-500 uppercase-font block">
                                growth rate
                              </span>
                            </div>
                            <div className="col-xs-6 text-center  pl-0 pr-0 pt-25  data-wrap-right">
                              <div
                                id="sparkline_4"
                                style={{
                                  width: 100,
                                  overflow: "hidden",
                                  margin: "0px auto",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Row */}
            {/* Row */}
            {/* <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="panel panel-default card-view panel-refresh">
                  <div className="refresh-container">
                    <div className="la-anim-1" />
                  </div>
                  <div className="panel-heading">
                    <div className="pull-left">
                      <h6 className="panel-title txt-dark">user statistics</h6>
                    </div>
                    <div className="pull-right">
                      <a
                        href="fakeurl"
                        className="pull-left inline-block refresh mr-15"
                      >
                        <i className="zmdi zmdi-replay" />
                      </a>
                      <a
                        href="fakeurl"
                        className="pull-left inline-block full-screen mr-15"
                      >
                        <i className="zmdi zmdi-fullscreen" />
                      </a>
                      <div className="pull-left inline-block dropdown">
                        <a
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          href="fakeurl"
                          aria-expanded="false"
                          role="button"
                        >
                          <i className="zmdi zmdi-more-vert" />
                        </a>
                        <ul
                          className="dropdown-menu bullet dropdown-menu-right"
                          role="menu"
                        >
                          <li role="presentation">
                            <a href="javascript:void(0)" role="menuitem">
                              <i className="icon wb-reply" aria-hidden="true" />
                              Devices
                            </a>
                          </li>
                          <li role="presentation">
                            <a href="javascript:void(0)" role="menuitem">
                              <i className="icon wb-share" aria-hidden="true" />
                              General
                            </a>
                          </li>
                          <li role="presentation">
                            <a href="javascript:void(0)" role="menuitem">
                              <i className="icon wb-trash" aria-hidden="true" />
                              Referral
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div id="e_chart_1" className style={{ height: 313 }} />
                      <ul className="flex-stat mt-40">
                        <li>
                          <span className="block">Weekly Users</span>
                          <span className="block txt-dark weight-500 font-18">
                            <span className="counter-anim">3,24,222</span>
                          </span>
                        </li>
                        <li>
                          <span className="block">Monthly Users</span>
                          <span className="block txt-dark weight-500 font-18">
                            <span className="counter-anim">1,23,432</span>
                          </span>
                        </li>
                        <li>
                          <span className="block">Trend</span>
                          <span className="block">
                            <i className="zmdi zmdi-trending-up txt-success font-24" />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="panel panel-default card-view">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body sm-data-box-1">
                      <span className="uppercase-font weight-500 font-14 block text-center txt-dark">
                        customer satisfaction
                      </span>
                      <div className="cus-sat-stat weight-500 txt-info text-center mt-5">
                        <span className="counter-anim">93.13</span>
                        <span>%</span>
                      </div>
                      <div className="progress-anim mt-20">
                        <div className="progress">
                          <div
                            className="progress-bar progress-bar-info
											wow animated progress-animated"
                            role="progressbar"
                            aria-valuenow="93.12"
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                      <ul className="flex-stat mt-5">
                        <li>
                          <span className="block">Previous</span>
                          <span className="block txt-dark weight-500 font-15">
                            79.82
                          </span>
                        </li>
                        <li>
                          <span className="block">% Change</span>
                          <span className="block txt-dark weight-500 font-15">
                            +14.29
                          </span>
                        </li>
                        <li>
                          <span className="block">Trend</span>
                          <span className="block">
                            <i className="zmdi zmdi-trending-up txt-success font-20" />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default card-view">
                  <div className="panel-heading">
                    <div className="pull-left">
                      <h6 className="panel-title txt-dark">browser stats</h6>
                    </div>
                    <div className="pull-right">
                      <a
                        href="fakeurl"
                        className="pull-left inline-block mr-15"
                      >
                        <i className="zmdi zmdi-download" />
                      </a>
                      <a
                        href="fakeurl"
                        className="pull-left inline-block close-panel"
                        data-effect="fadeOut"
                      >
                        <i className="zmdi zmdi-close" />
                      </a>
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div>
                        <span className="pull-left inline-block capitalize-font txt-dark">
                          google chrome
                        </span>
                        <span className="label label-warning pull-right">
                          50%
                        </span>
                        <div className="clearfix" />
                        <hr className="light-grey-hr row mt-10 mb-10" />
                        <span className="pull-left inline-block capitalize-font txt-dark">
                          mozila firefox
                        </span>
                        <span className="label label-danger pull-right">
                          10%
                        </span>
                        <div className="clearfix" />
                        <hr className="light-grey-hr row mt-10 mb-10" />
                        <span className="pull-left inline-block capitalize-font txt-dark">
                          Internet explorer
                        </span>
                        <span className="label label-success pull-right">
                          30%
                        </span>
                        <div className="clearfix" />
                        <hr className="light-grey-hr row mt-10 mb-10" />
                        <span className="pull-left inline-block capitalize-font txt-dark">
                          safari
                        </span>
                        <span className="label label-primary pull-right">
                          10%
                        </span>
                        <div className="clearfix" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="panel panel-default card-view panel-refresh">
                  <div className="refresh-container">
                    <div className="la-anim-1" />
                  </div>
                  <div className="panel-heading">
                    <div className="pull-left">
                      <h6 className="panel-title txt-dark">
                        Visit by Traffic Types
                      </h6>
                    </div>
                    <div className="pull-right">
                      <a
                        href="fakeurl"
                        className="pull-left inline-block refresh mr-15"
                      >
                        <i className="zmdi zmdi-replay" />
                      </a>
                      <div className="pull-left inline-block dropdown">
                        <a
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          href="fakeurl"
                          aria-expanded="false"
                          role="button"
                        >
                          <i className="zmdi zmdi-more-vert" />
                        </a>
                        <ul
                          className="dropdown-menu bullet dropdown-menu-right"
                          role="menu"
                        >
                          <li role="presentation">
                            <a href="javascript:void(0)" role="menuitem">
                              <i className="icon wb-reply" aria-hidden="true" />
                              Devices
                            </a>
                          </li>
                          <li role="presentation">
                            <a href="javascript:void(0)" role="menuitem">
                              <i className="icon wb-share" aria-hidden="true" />
                              General
                            </a>
                          </li>
                          <li role="presentation">
                            <a href="javascript:void(0)" role="menuitem">
                              <i className="icon wb-trash" aria-hidden="true" />
                              Referral
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div id="e_chart_2" className style={{ height: 215 }} />
                      <hr className="light-grey-hr row mt-10 mb-15" />
                      <div className="label-chatrs">
                        <div className>
                          <span className="clabels clabels-lg inline-block bg-purple mr-10 pull-left" />
                          <span className="clabels-text font-12 inline-block txt-dark capitalize-font pull-left">
                            <span className="block font-15 weight-500 mb-5">
                              44.46% organic
                            </span>
                            <span className="block txt-grey">356 visits</span>
                          </span>
                          <div
                            id="sparkline_1"
                            className="pull-right"
                            style={{
                              width: 100,
                              overflow: "hidden",
                              margin: "0px auto",
                            }}
                          />
                          <div className="clearfix" />
                        </div>
                      </div>
                      <hr className="light-grey-hr row mt-10 mb-15" />
                      <div className="label-chatrs">
                        <div className>
                          <span className="clabels clabels-lg inline-block bg-yellow mr-10 pull-left" />
                          <span className="clabels-text font-12 inline-block txt-dark capitalize-font pull-left">
                            <span className="block font-15 weight-500 mb-5">
                              5.54% Refrral
                            </span>
                            <span className="block txt-grey">36 visits</span>
                          </span>
                          <div
                            id="sparkline_2"
                            className="pull-right"
                            style={{
                              width: 100,
                              overflow: "hidden",
                              margin: "0px auto",
                            }}
                          />
                          <div className="clearfix" />
                        </div>
                      </div>
                      <hr className="light-grey-hr row mt-10 mb-15" />
                      <div className="label-chatrs">
                        <div className>
                          <span className="clabels clabels-lg inline-block bg-red mr-10 pull-left" />
                          <span className="clabels-text font-12 inline-block txt-dark capitalize-font pull-left">
                            <span className="block font-15 weight-500 mb-5">
                              50% Other
                            </span>
                            <span className="block txt-grey">245 visits</span>
                          </span>
                          <div
                            id="sparkline_3"
                            className="pull-right"
                            style={{
                              width: 100,
                              overflow: "hidden",
                              margin: "0px auto",
                            }}
                          />
                          <div className="clearfix" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* /Row */}
            {/* Row */}
            {/* <div className="row">
              <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
                <div className="panel panel-default card-view panel-refresh">
                  <div className="refresh-container">
                    <div className="la-anim-1" />
                  </div>
                  <div className="panel-heading">
                    <div className="pull-left">
                      <h6 className="panel-title txt-dark">social campaigns</h6>
                    </div>
                    <div className="pull-right">
                      <a
                        href="fakeurl"
                        className="pull-left inline-block refresh mr-15"
                      >
                        <i className="zmdi zmdi-replay" />
                      </a>
                      <a
                        href="fakeurl"
                        className="pull-left inline-block full-screen mr-15"
                      >
                        <i className="zmdi zmdi-fullscreen" />
                      </a>
                      <div className="pull-left inline-block dropdown">
                        <a
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          href="fakeurl"
                          aria-expanded="false"
                          role="button"
                        >
                          <i className="zmdi zmdi-more-vert" />
                        </a>
                        <ul
                          className="dropdown-menu bullet dropdown-menu-right"
                          role="menu"
                        >
                          <li role="presentation">
                            <a href="javascript:void(0)" role="menuitem">
                              <i className="icon wb-reply" aria-hidden="true" />
                              Edit
                            </a>
                          </li>
                          <li role="presentation">
                            <a href="javascript:void(0)" role="menuitem">
                              <i className="icon wb-share" aria-hidden="true" />
                              Delete
                            </a>
                          </li>
                          <li role="presentation">
                            <a href="javascript:void(0)" role="menuitem">
                              <i className="icon wb-trash" aria-hidden="true" />
                              New
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body row pa-0">
                      <div className="table-wrap">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0">
                            <thead>
                              <tr>
                                <th>Campaign</th>
                                <th>Client</th>
                                <th>Changes</th>
                                <th>Budget</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <span className="txt-dark weight-500">
                                    Facebook
                                  </span>
                                </td>
                                <td>Beavis</td>
                                <td>
                                  <span className="txt-success">
                                    <i className="zmdi zmdi-caret-up mr-10 font-20" />
                                    <span>2.43%</span>
                                  </span>
                                </td>
                                <td>
                                  <span className="txt-dark weight-500">
                                    $1478
                                  </span>
                                </td>
                                <td>
                                  <span className="label label-primary">
                                    Active
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="txt-dark weight-500">
                                    Youtube
                                  </span>
                                </td>
                                <td>Felix</td>
                                <td>
                                  <span className="txt-success">
                                    <i className="zmdi zmdi-caret-up mr-10 font-20" />
                                    <span>1.43%</span>
                                  </span>
                                </td>
                                <td>
                                  <span className="txt-dark weight-500">
                                    $951
                                  </span>
                                </td>
                                <td>
                                  <span className="label label-danger">
                                    Closed
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="txt-dark weight-500">
                                    Twitter
                                  </span>
                                </td>
                                <td>Cannibus</td>
                                <td>
                                  <span className="txt-danger">
                                    <i className="zmdi zmdi-caret-down mr-10 font-20" />
                                    <span>-8.43%</span>
                                  </span>
                                </td>
                                <td>
                                  <span className="txt-dark weight-500">
                                    $632
                                  </span>
                                </td>
                                <td>
                                  <span className="label label-default">
                                    Hold
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="txt-dark weight-500">
                                    Spotify
                                  </span>
                                </td>
                                <td>Neosoft</td>
                                <td>
                                  <span className="txt-success">
                                    <i className="zmdi zmdi-caret-up mr-10 font-20" />
                                    <span>7.43%</span>
                                  </span>
                                </td>
                                <td>
                                  <span className="txt-dark weight-500">
                                    $325
                                  </span>
                                </td>
                                <td>
                                  <span className="label label-default">
                                    Hold
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span className="txt-dark weight-500">
                                    Instagram
                                  </span>
                                </td>
                                <td>Libi Technologies</td>
                                <td>
                                  <span className="txt-success">
                                    <i className="zmdi zmdi-caret-up mr-10 font-20" />
                                    <span>9.43%</span>
                                  </span>
                                </td>
                                <td>
                                  <span className="txt-dark weight-500">
                                    $258
                                  </span>
                                </td>
                                <td>
                                  <span className="label label-primary">
                                    Active
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                <div className="panel panel-default card-view panel-refresh">
                  <div className="refresh-container">
                    <div className="la-anim-1" />
                  </div>
                  <div className="panel-heading">
                    <div className="pull-left">
                      <h6 className="panel-title txt-dark">
                        Advertising &amp; Promotions
                      </h6>
                    </div>
                    <div className="pull-right">
                      <a
                        href="fakeurl"
                        className="pull-left inline-block refresh mr-15"
                      >
                        <i className="zmdi zmdi-replay" />
                      </a>
                      <div className="pull-left inline-block dropdown">
                        <a
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          href="fakeurl"
                          aria-expanded="false"
                          role="button"
                        >
                          <i className="zmdi zmdi-more-vert" />
                        </a>
                        <ul
                          className="dropdown-menu bullet dropdown-menu-right"
                          role="menu"
                        >
                          <li role="presentation">
                            <a href="javascript:void(0)" role="menuitem">
                              <i className="icon wb-reply" aria-hidden="true" />
                              option 1
                            </a>
                          </li>
                          <li role="presentation">
                            <a href="javascript:void(0)" role="menuitem">
                              <i className="icon wb-share" aria-hidden="true" />
                              option 2
                            </a>
                          </li>
                          <li role="presentation">
                            <a href="javascript:void(0)" role="menuitem">
                              <i className="icon wb-trash" aria-hidden="true" />
                              option 3
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div id="e_chart_3" className style={{ height: 236 }} />
                      <div className="label-chatrs text-center mt-30">
                        <div className="inline-block mr-15">
                          <span className="clabels inline-block bg-purple mr-5" />
                          <span className="clabels-text font-12 inline-block txt-dark capitalize-font">
                            Active
                          </span>
                        </div>
                        <div className="inline-block mr-15">
                          <span className="clabels inline-block bg-red mr-5" />
                          <span className="clabels-text font-12 inline-block txt-dark capitalize-font">
                            Closed
                          </span>
                        </div>
                        <div className="inline-block">
                          <span className="clabels inline-block bg-yellow mr-5" />
                          <span className="clabels-text font-12 inline-block txt-dark capitalize-font">
                            Hold
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Row */}
          </div>
          {/* Footer */}
          <footer className="footer container-fluid pl-30 pr-30">
            <div className="row">
              <div className="col-sm-12">
                <p>2021 © Libicon. Pampered by Libi Technologies</p>
              </div>
            </div>
          </footer>
          {/* /Footer */}
        </div>
        {/* /Main Content */}
      </div>
    );
  }
}
