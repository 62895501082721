import React from "react";
import AddSim from "./AddSim";
import AddDevice from "./AddDevice";
import { Link } from "react-router-dom";

class AddInventory extends React.Component {
  render() {
    return (
      <div>
        <div className="page-wrapper">
          <div className="container-fluid">
            {/* Title */}
            <div className="row heading-bg">
              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h5 className="txt-dark">Inventory Details</h5>
              </div>
              {/* Breadcrumb */}
              <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <ol className="breadcrumb">
                  <li>
                    <a href="index-2.html">Dashboard</a>
                  </li>
                  <li className="active">
                    <span>Add Inventory</span>
                  </li>
                </ol>
              </div>
              {/* /Breadcrumb */}
            </div>
            <div className="tab-struct custom-tab-2 ">
              <ul role="tablist" className="nav nav-tabs" id="myTabs_15">
                <li
                  className="active"
                  role="presentation"
                  to="/inventory/devices/add"
                >
                  <a
                    aria-expanded="true"
                    data-toggle="tab"
                    role="tab"
                    id="home_tab_15"
                    href="#home_15"
                  >
                    Device
                  </a>
                </li>
                <li role="presentation" className to="/inventory/sims/add">
                  <a
                    data-toggle="tab"
                    id="profile_tab_15"
                    role="tab"
                    href="#profile_15"
                    aria-expanded="false"
                  >
                    Sim
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent_15">
                <AddDevice />
                {/* /Title */}
                <AddSim />
              </div>
            </div>
            <footer className="footer container-fluid pl-30 pr-30">
              <div className="row">
                <div className="col-sm-12">
                  <p>2017 © Libicon. Pampered by Libi Technologies</p>
                </div>
              </div>
            </footer>
            {/* /Footer */}
          </div>
        </div>
      </div>
    );
  }
}

export default AddInventory;
