import React from "react";

import { Field,  reduxForm } from "redux-form";
import { connect } from "react-redux";
import { signIn, fetchUser } from "../actions";
import history from "../history";

class Login extends React.Component {
  renderFormFields = () => {
    const { handleSubmit, pristine, submitting } = this.props;
    return (
      <section
        class="fxt-template-animation fxt-template-layout23"
        data-bg-image="img/figure/bg23-l.jpg"
        style={{ backgroundImage: "url(img/figure/bg23-l.jpg)" }}
      >
        <div
          class="fxt-bg-overlay"
          data-bg-image="img/elements/overlay.png"
          style={{ backgroundImage: "url(img/elements/overlay.png)" }}
        >
          <div class="fxt-content">
            <div class="fxt-header">
              <img src="img/logo-23.png" alt="Logo" />
            </div>
            <div class="fxt-form">
              <p>Login into your account</p>
              <hr/>
              <form onSubmit={handleSubmit(this.onFormSubmit)}>
                <div class="form-group">
                  <div class="fxt-transformY-50 fxt-transition-delay-1">
                    <Field
                      id="empcode"
                      type="text"
                      name="empCode"
                      placeholder="Employee Code"
                      component={this.renderInput}
                    />
                    {/* <input type="text" id="empcode2" class="form-control" name="empCode" placeholder="Employee Code" required="required"/> */}
                  </div>
                </div>
                <div class="form-group">
                  <div class="fxt-transformY-50 fxt-transition-delay-2">
                    <Field
                      id="password"
                      type="password"
                      name="password"
                      placeholder="********"
                      component={this.renderInput}
                    />
                    {/* <input id="password" type="password" class="form-control" name="password" placeholder="********" required="required"/> */}
                    <i
                      toggle="#password"
                      class="fa fa-fw fa-eye toggle-password field-icon"
                    ></i>
                  </div>
                </div>
                <div className="form-group">
                  
                </div>
                <div class="form-group">
                  <div class="fxt-transformY-50 fxt-transition-delay-4">
                    <button
                      type="submit"
                      class="fxt-btn-fill"
                      disabled={pristine || submitting}
                    >
                      Log in
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  };
  renderErrorMessage = (meta) => {
    if (meta.touched) {
      return <div className="form__error">{meta.error}</div>;
    } else {
      return null;
    }
  };
  renderInput = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <input
          className="form-control"
          type={formProps.type}
          placeholder={formProps.placeholder}
          {...formProps.input}
        />
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };

  componentDidMount() {
    // console.log(this.token)
    // if (this.token) {
    //     history.push('/dashboard');
    //   }

    if (!this.props.token) {
      history.push("/");
    }
  }

  onFormSubmit = (formValues) => {
    console.log(this.props);
    this.props.signIn(formValues);
    // this.props.fetchUser();
    // console.log('Login Successful!', formValues)
  };
  render() {
    this.props.fetchUser();
    return this.renderFormFields();
  }
}

// const mapStateToProps = (state) => {
//   return { user: state.auth.token };
// };
const validate = (formValues) => {
  const errors = {};
  // var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!formValues.username) {
    errors.username = "You must enter your username";
  } else if (formValues.username.length < 5) {
    errors.username = "Employee Id must be atleast 7 long";
  }
  if (!formValues.pswd) {
    errors.pswd = "You must enter the password";
  } else if (formValues.pswd.length < 6) {
    errors.pswd = "Password must be atleast 6 long charector";
  }
  return errors;
};

const mapStateToProp = (state) => {
  return { token: state.auth.token };
};
export default connect(mapStateToProp, { signIn, fetchUser })(
  reduxForm({
    form: "loginForm",
    validate,
  })(Login)
);
