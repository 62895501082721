import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import clientsReducer from "./clientsReducer";
import employeeReducer from "./employeeReducer";
import vendorReducre from "./vendorReducre";
import deviceReducer from "./deviceReducer";
import simReducer from "./simReducer";
import orderReducer from "./orderReducer";
import batchReducer from "./batchReducer";
import leadReducer from "./leadReducer";
import userReducer from "./userReducer";
export default combineReducers({
  auth: authReducer,
  user: userReducer,
  order: orderReducer,
  lead: leadReducer,
  clients: clientsReducer,
  form: formReducer,
  employee: employeeReducer,
  vendor: vendorReducre,
  device: deviceReducer,
  sim: simReducer,
  batch: batchReducer,
});
