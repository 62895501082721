import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { getAllEmployeeName, updateClient } from "../../actions";

class ClientForm extends React.Component {
  //state for ad dand edit button
  state = { mode: "Add" };
  componentDidMount() {
    // this.props.fetchUser();
    //console.log(this.props.token);
    this.props.getAllEmployeeName(this.props.token);
  }
  //Render Options in select tag

  renderOptionList = (array) => {
    return array.map((el) => {
      return (
        <option key={array.indexOf(el) + 1} value={el}>
          {el}
        </option>
      );
    });
  };

  //country list array
  country_list = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia &amp; Herzegovina",
    "Botswana",
    "Brazil",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Cape Verde",
    "Cayman Islands",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Cote D Ivoire",
    "Croatia",
    "Cruise Ship",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "French West Indies",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kuwait",
    "Kyrgyz Republic",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norway",
    "Oman",
    "Pakistan",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Pierre &amp; Miquelon",
    "Samoa",
    "San Marino",
    "Satellite",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "South Africa",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "St Kitts &amp; Nevis",
    "St Lucia",
    "St Vincent",
    "St. Lucia",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor L'Este",
    "Togo",
    "Tonga",
    "Trinidad &amp; Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks &amp; Caicos",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "Uruguay",
    "Uzbekistan",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (US)",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  state_list = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadar and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Lakshadeep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Orissa",
    "Pondicherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttaranchal",
    "Uttar Pradesh",
    "West Bengal",
  ]

  //form submit click handler
  onFormSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  //Render Error Message
  renderErrorMessage = (meta) => {
    if (meta.touched) {
      return <div className="form__error">{meta.error}</div>;
    } else {
      return null;
    }
  };

  //convert input to redux field

  renderInput = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <input
          className="form-control"
          type={formProps.type}
          placeholder={formProps.placeholder}
          {...formProps.input}
        />
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };

  //convert textarea to redux field
  renderTextArea = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <textarea
          className="form-control"
          type={formProps.type}
          placeholder={formProps.placeholder}
          {...formProps.input}
        />
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };
  //convert select to redux field

  renderSelectTag = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <select
          className="form-control"
          type={formProps.type}
          {...formProps.input}
        />
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };

  //render form

  renderFormFields = () => {
    const { handleSubmit, pristine, submitting } = this.props;
    return (
      <div className="form-wrap">
        <form
          onSubmit={handleSubmit(this.onFormSubmit)}
          className="form-horizontal"
        >
          <div className="form-body">
            <h6 className="txt-dark capitalize-font">
              <i className="zmdi zmdi-account mr-10" />
              Company's Info
            </h6>
            <hr className="light-grey-hr" />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">Company Name</label>
                  <div className="col-md-9">
                    <Field
                      name="companyName"
                      type="text"
                      className="form-control"
                      component={this.renderInput}
                      placeholder="eg.Libi Technologies Pvt. Ltd"
                    />
                  </div>
                </div>
              </div>
              {/*/span*/}
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">
                    Contact Person
                  </label>
                  <div className="col-md-9">
                    <Field
                      type="text"
                      name="contactPerson"
                      component={this.renderInput}
                      className="form-control"
                      placeholder="eg.Sonu Raj"
                    />
                  </div>
                </div>
              </div>
              {/*/span*/}
            </div>
            {/* /Row */}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">Client Role</label>
                  <div className="col-md-9">
                    <Field
                      className="form-control"
                      name="clientType"
                      component="select"
                    >
                      <option values="">Select Client Type</option>
                      <option value="Dealer">Dealer</option>
                      <option value="User">User</option>
                    </Field>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">Mobile No</label>
                  <div className="col-md-9">
                    <Field
                      type="text"
                      name="number"
                      className="form-control"
                      component={this.renderInput}
                      placeholder="eg.9876543210"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*/span*/}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">Email</label>
                  <div className="col-md-9">
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      component={this.renderInput}
                      placeholder="eg.name@company.com"
                    />
                  </div>
                </div>
              </div>
              {/*/span*/}
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">GST</label>
                  <div className="col-md-9">
                    <Field
                      type="text"
                      name="gst"
                      className="form-control"
                      component={this.renderInput}
                      placeholder="eg.10JKHASDG152345JK"
                    />
                  </div>
                </div>
              </div>
              {/*/span*/}
            </div>
            {/*/span*/}

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">Sales Person</label>
                  <div className="col-md-9">
                    <Field
                      component="select"
                      className="form-control"
                      name="salesPerson"
                    >
                      <option values="">Select Sales Person</option>
                      {!this.props.employee.employeeName
                        ? "No Employee"
                        : this.renderOptionList(
                          this.props.employee.employeeName
                        )}
                    </Field>
                  </div>
                </div>
              </div>
              {/*/span*/}
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">
                    Account Manager
                  </label>
                  <div className="col-md-9">
                    <Field
                      className="form-control"
                      name="accMgr"
                      component="select"
                    >
                      <option values="">Select Account Manager</option>
                      {!this.props.employee.employeeName
                        ? "No Employee"
                        : this.renderOptionList(
                          this.props.employee.employeeName
                        )}
                    </Field>
                  </div>
                </div>
              </div>
              {/*/span*/}
            </div>
            {/*/span*/}

            <div className="seprator-block" />
            <h6 className="txt-dark capitalize-font">
              <i className="zmdi zmdi-account-box mr-10" />
              Address
            </h6>
            <hr className="light-grey-hr" />
            {/* /Row */}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">Address</label>
                  <div className="col-md-9">
                    <Field
                      type="textarea"
                      name="address"
                      component={this.renderTextArea}
                      className="form-control"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">Address 2</label>
                  <div className="col-md-9">
                    <Field
                      type="text"
                      name="address2"
                      component={this.renderTextArea}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">City</label>
                  <div className="col-md-9">
                    <Field
                      type="text"
                      name="city"
                      component={this.renderInput}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              {/*/span*/}
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">State</label>
                  <div className="col-md-9">
                    <Field
                      className="form-control"
                      name="state"
                      component="select"
                    >
                      <option values="">Select State</option>
                      {this.renderOptionList(this.state_list)}
                    </Field>
                  </div>
                </div>
              </div>
              {/*/span*/}
            </div>
            {/* /Row */}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">Post Code</label>
                  <div className="col-md-9">
                    <Field
                      type="text"
                      name="pinCode"
                      component={this.renderInput}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              {/*/span*/}
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label col-md-3">Country</label>
                  <div className="col-md-9">
                    <Field
                      className="form-control"
                      name="country"
                      component="select"
                    >
                      <option values="">Select Country</option>
                      {this.renderOptionList(this.country_list)}
                    </Field>
                  </div>
                </div>
              </div>
              {/*/span*/}
            </div>
            {/* /Row */}
          </div>
          <div className="form-actions mt-10">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-offset-3 col-md-9">
                    {this.props.mode === "Add" ? (
                      <button
                        type="submit"
                        disabled={pristine || submitting}
                        className="btn btn-success  mr-10"
                      >
                        Submit
                      </button>
                    ) : (
                        <button
                          type="submit"
                          disabled={pristine || submitting}
                          className="btn btn-danger  mr-10"
                        >
                          Update
                        </button>
                      )}

                    <Link
                      type="button"
                      className="btn btn-default"
                      to="/clients"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6"> </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* Title */}
          <div className="row heading-bg">
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <h5 className="txt-dark">Client Details</h5>
            </div>
            {/* Breadcrumb */}
            <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
              <ol className="breadcrumb">
                <li>
                  <Link
                    to="/"
                    onClick={() => {
                      setTimeout(() => {
                        window.location.reload(true);
                      }, 50);
                    }}
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="active">
                  <span>{this.props.mode} Client</span>
                </li>
              </ol>
            </div>
            {/* /Breadcrumb */}
          </div>
          {/* /Title */}
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-default card-view">
                <div className="panel-heading">
                  <div className="pull-left">
                    <h6 className="panel-title txt-dark">
                      Client {this.props.mode} Form
                    </h6>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="panel-wrapper collapse in">
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-md-12">{this.renderFormFields()}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <footer className="footer container-fluid pl-30 pr-30">
            <div className="row">
              <div className="col-sm-12">
                <p>2017 © Libicon. Pampered by Libi Technologies</p>
              </div>
            </div>
          </footer>
          {/* /Footer */}
        </div>
      </div>
    );
  }
}
//Value pick form redux store to props

const mapStateToProp = (state) => {
  return {
    employee: state.employee,
  };
};

//Client Side Form validation
const validate = (formValues) => {
  const errors = {};
  // var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!formValues.username) {
    errors.username = "You must enter your username";
  } else if (formValues.username.length < 5) {
    errors.username = "Employee Id must be atleast 7 long";
  }
  if (!formValues.pswd) {
    errors.pswd = "You must enter the password";
  } else if (formValues.pswd.length < 6) {
    errors.pswd = "Password must be atleast 6 long charector";
  }
  return errors;
};

//Connect with redux form with local redux store
export default connect(mapStateToProp, {
  getAllEmployeeName,
})(
  reduxForm({
    form: "client form",
    validate,
  })(ClientForm)
);
