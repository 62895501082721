import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import {
  getAllDevice,
  getDeviceBySearchValue,
  getAllEmployeeName,
  fetchAllBatchNumber,
  getAllVendor,
  getAllDeviceModelName,
} from "../../actions/index";
import Modal from "../Fixed/Modal";
class Device extends React.Component {
  //state for ad dand edit button
  state = { pageNumber: 0 };

  componentDidMount() {
    this.props.getAllDevice(this.state.pageNumber);
    this.props.getAllEmployeeName();
    this.props.getAllDeviceModelName();
    this.props.fetchAllBatchNumber();
    this.props.getAllVendor();
  }

  //convert input to redux field
  renderInput = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <input
          className="form-control"
          type={formProps.type}
          placeholder={formProps.placeholder}
          {...formProps.input}
        />
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };
  //Render Options in select tag

  renderOptionList = (array) => {
    return array.map((el) => {
      return (
        <option key={array.indexOf(el) + 1} value={el}>
          {el}
        </option>
      );
    });
  };

  //Device Location

  Location = ["Employee", "Dealer", "Vender", "User", "Office"];

  //Device Status

  Status = [
    "New",
    "Replaced",
    "Installed",
    "Under Repair",
    "Sold",
    "Repaired",
    "Void",
    "Other",
  ];
  //convert select to redux field

  renderSelectTag = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <select
          className="form-control"
          type={formProps.type}
          {...formProps.input}
        >
          {formProps.children}
        </select>
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };

  //render Device Data for table
  renderDataDevice = (devices) => {
    return devices.map((el) => {
      return (
        <tr key={devices.indexOf(el) + 1}>
          <td>{this.state.pageNumber + devices.indexOf(el) + 1}</td>
          <td>{el.Model}</td>
          <td>{el.IMEI}</td>
          <td>{el.Vendor}</td>
          <td>{el.DeviceLocation}</td>
          <td>{el.LocationName}</td>
          <td>{el.Status}</td>
          <td>{el.AddedDate}</td>
          <td>{el.AddedBy}</td>
          <td>
            <Link
              href="javascript:void(0)"
              className="text-inverse pr-10"
              type="button"
              title
              data-toggle="modal"
              data-target="#editdevicemodal"
              data-whatever="@mdo"
              data-original-title="Edit Device"
            >
              <i className="zmdi zmdi-edit txt-warning" />
            </Link>
            <a
              href="javascript:void(0)"
              className="text-inverse"
              title
              data-toggle="tooltip"
              data-original-title="Notes"
            >
              <i className="zmdi zmdi-book txt-success" />
            </a>
          </td>
        </tr>
      );
    });
  };

  //Search Device
  SearchDevice = (e) => {
    this.props.getDeviceBySearchValue(e.target.value);
  };

  //Next Button Handler

  nextButtonHandler = () => {
    if (this.props.devices.totalDevices >= this.state.pageNumber) {
      this.props.getAllDevice(this.state.pageNumber + 10);
      let newPageNumber = this.state.pageNumber + 10;
      this.setState({ pageNumber: newPageNumber });
    }
  };

  //Previous Button Handler

  perviousButtonHandler = () => {
    if (this.state.pageNumber > 0) {
      this.props.getAllDevice(this.state.pageNumber - 10);
      let newPageNumber = this.state.pageNumber - 10;
      this.setState({ pageNumber: newPageNumber });
    }
  };

  //Edit Button Handler
  editButtonClickHandler = (e) => {
    // this.props.fetchClientByClid();
    console.log(e.target.parentNode.parentNode.parentNode.parentNode);
  };
  //cards

  //main render function
  render() {
    return (
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* Title */}
          <div className="row heading-bg">
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <h5 className="txt-dark" style={{ float: "left" }}>
                Device Details
              </h5>
              <Link
                to="/inventory/add"
                className="btn btn-primary"
                style={{ marginLeft: 20 }}
              >
                Add New
              </Link>
            </div>
            {/* Breadcrumb */}
            <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
              <ol className="breadcrumb">
                <li>
                  <Link
                    to="/"
                    onClick={() => {
                      setTimeout(() => {
                        window.location.reload(true);
                      }, 50);
                    }}
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="active">
                  <span>Show Device</span>
                </li>
              </ol>
            </div>
            {/* /Breadcrumb */}
          </div>
          {/* /Title */}
          {/* Row */}

          <div className="row">
            <div className="col-sm-12">
              <div className="panel panel-default card-view">
                <div className="panel-wrapper collapse in">
                  <div className="panel-body">
                    <div class="dt-buttons">
                      <a
                        class="dt-button buttons-copy buttons-html5"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>Copy</span>
                      </a>
                      <a
                        class="dt-button buttons-csv buttons-html5"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>CSV</span>
                      </a>
                      <a
                        class="dt-button buttons-excel buttons-html5"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>Excel</span>
                      </a>
                      <a
                        class="dt-button buttons-pdf buttons-html5"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>PDF</span>
                      </a>
                      <a
                        class="dt-button buttons-print"
                        tabindex="0"
                        aria-controls="example"
                        href="fakeurl"
                      >
                        <span>Print</span>
                      </a>
                    </div>
                    <div style={{ float: "right" }} class="dataTables_filter">
                      <label>
                        Search:{" "}
                        <input
                          type="search"
                          class=""
                          onChange={this.SearchDevice}
                          placeholder=""
                          aria-controls="example"
                        />
                      </label>
                    </div>
                    <div
                      class="dataTables_info"
                      style={{ textAlign: "center" }}
                      role="status"
                      aria-live="polite"
                    >
                      {this.props.devices.devices &&
                      this.props.devices.devices.devices.length > 0
                        ? `Showing ${this.state.pageNumber + 1} to ${
                            this.state.pageNumber +
                            this.props.devices.devices.devices.length
                          } of
                  ${this.props.devices.totalDevices} entries`
                        : "No record found!"}
                    </div>

                    <div className="table-wrap" style={{ paddingTop: "20px" }}>
                      <div className="table-responsive">
                        <table
                          id="example"
                          className="table table-hover display  pb-30"
                        >
                          <thead>
                            <tr>
                              <th>Sno</th>
                              <th>Model</th>
                              <th>IMEI</th>
                              <th>VENDOR</th>
                              <th>Location</th>
                              <th>Location Name</th>
                              <th>Status</th>
                              <th>Added Date</th>
                              <th>Added By</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {!this.props.devices.devices
                              ? "no data"
                              : this.renderDataDevice(
                                  this.props.devices.devices.devices
                                )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="col-sm-12"
                      style={{ textAlign: "right", marginTop: "20px" }}
                    >
                      <button
                        className="btn btn-success"
                        name="previous"
                        type="button"
                        style={{ marginRight: "20px" }}
                        onClick={this.perviousButtonHandler}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-success"
                        name="next"
                        type="button"
                        onClick={this.nextButtonHandler}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Edit Device Model */}
          <Modal header={`Edit Device#86190261525`} id="editdevicemodal">
            <div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label className="control-label mb-10">IMEI</label>

                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputuname"
                      placeholder={153}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">Vendor</label>
                    <Field
                      component="select"
                      className="form-control"
                      name="vendor"
                    >
                      <option values="">Select Vendor</option>
                      {!this.props.vendor.vendors
                        ? "No Employee"
                        : this.renderOptionList(this.props.vendor.vendors)}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">Batch</label>

                    <Field
                      component="select"
                      className="form-control"
                      name="batchNo"
                      tabIndex={1}
                    >
                      <option value="">Select Batch </option>
                      {!this.props.batch.batchNumber
                        ? "No Batch"
                        : this.renderOptionList(this.props.batch.batchNumber)}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">Device Model</label>

                    <Field
                      component="select"
                      className="form-control"
                      name="model"
                      tabIndex={1}
                    >
                      <option value="">Select model </option>
                      {!this.props.deviceModel.deviceModel
                        ? "No Employee"
                        : this.renderOptionList(
                            this.props.deviceModel.deviceModel
                          )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">Location</label>
                    <Field
                      component="select"
                      className="form-control"
                      name="location"
                      tabIndex={1}
                      initialValues="Office"
                      enalble
                    >
                      <option value="">Select Location </option>
                      {this.renderOptionList(this.Location)}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">Location Name</label>
                    <Field
                      component="select"
                      className="form-control"
                      name="locationName"
                      enalble
                    >
                      <option values="">Select Location Name</option>
                      {!this.props.employee.employeeName
                        ? "No Employee"
                        : this.renderOptionList(
                            this.props.employee.employeeName
                          )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label className="control-label mb-10">Status</label>
                    <Field
                      component="select"
                      className="form-control"
                      name="status"
                      tabIndex={1}
                      enalble
                    >
                      <option value="">Select Status </option>
                      {this.renderOptionList(this.Status)}
                    </Field>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-warning btn-lable-wrap right-label"
                  id="sa-params"
                >
                  <span className="btn-text" data-dismiss="modal">
                    Close
                  </span>{" "}
                  <span className="btn-label">
                    <i className="fa fa-times" />{" "}
                  </span>
                </button>
                <button
                  className="btn btn-success btn-icon right-icon"
                  id="sa-success"
                >
                  <span>Update</span> <i className="fa fa-check" />{" "}
                </button>
              </div>
            </div>
          </Modal>

          {/* Footer */}
          <footer className="footer container-fluid pl-30 pr-30">
            <div className="row">
              <div className="col-sm-12">
                <p>2020 © Libicon. Pampered by Libi Technologies</p>
              </div>
            </div>
          </footer>
          {/* /Footer */}
        </div>
      </div>
    );
  }
}

//Value pick form redux store to props

const mapStateToProp = (state) => {
  console.log(state.device.devices);
  return {
    devices: state.device,
    employee: state.employee,
    batch: state.batch,
    vendor: state.vendor,
    deviceModel: state.device,
    formValues: state.form.DeviceForm,
  };
};

//Connect with redux form with local redux store
export default connect(mapStateToProp, {
  getAllDevice,
  getDeviceBySearchValue,
  getAllEmployeeName,
  fetchAllBatchNumber,
  getAllVendor,
  getAllDeviceModelName,
})(
  reduxForm({
    form: "DeviceForm",
  })(Device)
);
