import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

class ConfirmOrderForm extends Component {
  state = { mode: null, DueAmount: 0, advAmount: 0 };
  componentDidMount() {
    // this.props.initialize({ due_amt: 100 });
    // set the value individually
    // this.props.dispatch(change("myFormName", "anotherField", "value"));
  }
  paymentMode = [
    "Cash",
    "Account",
    "Paytm",
    "Cheque",
    "No Payment",
    "Debit Note",
    "Credit Note",
  ];

  accounts = [
    "53380200000163(LLP)",
    "53380200000207(LTPL)",
    "53380200000030(Libi Technologies)",
    "53380200000137(Libi Technologies)",
    "50200039612061(Ritu Pandey)",
    "50200039612061(Madhuri)",
  ];
  //convert select to redux field
  renderSelectTag = (formProps) => {
    //this formProps attached with reduxForm
    // console.log(formProps);
    // console.log(this.props);

    return (
      <React.Fragment>
        <select
          className="form-control"
          type={formProps.type}
          {...formProps.input}
        >
          {formProps.children}
        </select>
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };

  //Render Options in select tag
  renderOptionList = (array) => {
    return array.map((el) => {
      return (
        <option key={array.indexOf(el) + 1} value={el}>
          {el}
        </option>
      );
    });
  };

  //convert input to redux field
  renderInput = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <input
          className="form-control"
          type={formProps.type}
          placeholder={formProps.placeholder}
          {...formProps.input}
        />
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };

  //convert textarea to redux field
  renderTextArea = (formProps) => {
    //this formProps attached with reduxForm
    console.log(formProps);
    console.log(this.props);

    return (
      <React.Fragment>
        <textarea
          className="form-control"
          type={formProps.type}
          placeholder={formProps.placeholder}
          {...formProps.input}
        />
        {this.renderErrorMessage(formProps.meta)}
      </React.Fragment>
    );
  };

  //Render Error Message
  renderErrorMessage = (meta) => {
    if (meta.touched) {
      return <div className="form__error">{meta.error}</div>;
    } else {
      return null;
    }
  };

  render() {
    return (
      <form>
        <div className="form-group">
          <label className="control-label mb-10">Payment Mode</label>
          <Field
            className="form-control"
            data-placeholder="Choose a Category"
            tabIndex={1}
            name="payment_mode"
            component={this.renderSelectTag}
            onChange={(e) => {
              this.setState({ mode: e.target.value });
              console.log(this.state.mode);
            }}
          >
            <option value>Select Payment Mode</option>
            {this.renderOptionList(this.paymentMode)}
          </Field>
        </div>
        {(this.state.mode === "Paytm" ||
          this.state.mode === "Debit Note" ||
          this.state.mode === "Credit Note") && (
          <div className="form-group">
            <label className="control-label mb-10">Reference Number</label>
            <div className="input-group">
              <div className="input-group-addon">
                <i className="ti-money" />
              </div>
              <Field
                type="text"
                className="form-control"
                name="paytm_ref_no"
                placeholder="XYZ110002200VF"
                component={this.renderInput}
              />
            </div>
          </div>
        )}

        {this.state.mode === "Cheque" && (
          <Fragment>
            <div className="form-group">
              <label className="control-label mb-10">Cheque Date</label>
              <Field
                type="datetime-local"
                className="form-control"
                placeholder="10/10/2020"
                name="chq_date"
                component={this.renderInput}
              />
            </div>
            <div className="form-group">
              <label className="control-label mb-10">Cheque Number</label>
              <Field
                type="text"
                className="form-control"
                placeholder="Cheque Number"
                name="chq_no"
                component={this.renderInput}
              />
            </div>
            <div className="form-group">
              <label className="control-label mb-10">Bank Name</label>
              <Field
                type="text"
                className="form-control"
                component={this.renderInput}
                name="bank_name"
              />
            </div>
            <div className="form-group">
              <label className="control-label mb-10">Cheque Posted To</label>
              <Field
                className="form-control"
                tabIndex={1}
                name="chq_posted_to"
                component={this.renderSelectTag}
              >
                <option value>Select Account Number</option>
                {this.renderOptionList(this.accounts)}
              </Field>
            </div>
            <div className="form-group">
              <label className="control-label mb-10">Cheque Status</label>
              <Field
                className="form-control"
                data-placeholder="Choose a Category"
                tabIndex={1}
                name="chq_status"
                component={this.renderSelectTag}
              >
                <option value>Cheque Status</option>
                <option value="Cleared">Cleared</option>
                <option value="Pending">Pending</option>
                <option value="Bounced">Bounced</option>
              </Field>
            </div>
          </Fragment>
        )}
        {this.state.mode === "Account" && (
          <div className="form-group">
            <label className="control-label mb-10">Account Number</label>
            <Field
              className="form-control"
              tabIndex={1}
              name="acc_no"
              component={this.renderSelectTag}
            >
              <option value>Select Account Number</option>
              {this.renderOptionList(this.accounts)}
            </Field>
          </div>
        )}
        {this.state.mode !== "No Payment" && (
          <Fragment>
            <div className="form-group">
              <label className="control-label mb-10">Received Amount</label>
              <div className="input-group">
                <div className="input-group-addon">
                  <i className="ti-money" />
                </div>
                <Field
                  type="number"
                  className="form-control"
                  name="received_amt"
                  placeholder={153}
                  component={this.renderInput}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="control-label mb-10">Due Amount</label>
              <div className="input-group">
                <div className="input-group-addon">
                  <i className="ti-money" />
                </div>
                <Field
                  type="number"
                  name="due_amt"
                  className="form-control"
                  value={this.state.DueAmount}
                  placeholder={153}
                  component={this.renderInput}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label mb-10">Advance Amount</label>
              <div className="input-group">
                <div className="input-group-addon">
                  <i className="ti-money" />
                </div>
                <Field
                  type="number"
                  name="adv_amt"
                  className="form-control"
                  placeholder={153}
                  component={this.renderInput}
                />
              </div>
            </div>
          </Fragment>
        )}
        <div className="form-group">
          <label className="control-label mb-10">Followup Date</label>
          <Field
            type="datetime-local"
            className="form-control"
            placeholder="10/10/2020"
            name="follow_up_date"
            component={this.renderInput}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message-text" className="control-label mb-10">
            Remarks
          </label>
          <Field
            className="form-control"
            name="note"
            placeholder="Any Other Details"
            component={this.renderTextArea}
          />
        </div>
      </form>
    );
  }
}

//Client Side Form validation
const validate = (formValues) => {
  const errors = {};
  // var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!formValues.username) {
    errors.username = "You must enter your username";
  } else if (formValues.username.length < 5) {
    errors.username = "Employee Id must be atleast 7 long";
  }
  if (!formValues.pswd) {
    errors.pswd = "You must enter the password";
  } else if (formValues.pswd.length < 6) {
    errors.pswd = "Password must be atleast 6 long charector";
  }
  return errors;
};

//Value pick form redux store to props

const mapStateToProp = (state, ownProps) => {
  console.log(ownProps, "123456");
  return {
    // order: state.order,
    // client: state.client,
    // deviceModel: state.device,
    formValues: state.form.confirmOrderForm,
    // initialValues: {
    //   received_amt: 1234567,
    //   due_amt: 500,
    // },
  };
};

//Connect with redux form with local redux store
export default connect(
  (state) => ({
    initialValues: { due_amt: state.DueAmount },
    enableReinitialize: true,
  }),
  {mapStateToProp}
)(
  reduxForm({
    form: "confirmOrderForm",
    validate,
  })(ConfirmOrderForm)
);
