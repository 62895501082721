import React from "react";
import { connect } from "react-redux";

import { Field, formValues, reduxForm } from "redux-form";
import {
  getAllEmployeeName,
  // checkIMEIExistence,
  fetchAllOrderNumber,
  updateSim,
  fetchOrderByCdid,
  fetchOrderItemByCdid,
} from "../../actions/index";

class UpdateSim extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    data: [],
    simNumArray: [],
    simCompany: [],
    value: "",
    typingTimeout: 0,
    location: "",
    locationName: "",
    orderId: "",
  };

  componentDidUpdate() {
    const node = this.myRef.current;
    node.value = "";
  }
  Location = ["Employee", "Dealer", "Vendor", "User", "Office"];

  //Sim Company

  simCompany = ["Airtel", "Vodafone", "Idea", "BSNL", "MTNL", "Jio"];

  //Render Options in select tag

  renderOptionList = (array) => {
    return array.map((el) => {
      return (
        <option key={array.indexOf(el) + 1} value={el}>
          {el}
        </option>
      );
    });
  };
  //render Device from state to screen
  renderDeviceList = () => {
    if (this.state.data.length > 0) {
      return this.state.data.map((el) => {
        return (
          <tr>
            <td>{el.orderId}</td>
            <td>{el.location}</td>
            <td>{el.locationName}</td>
            <td>{el.simCompany}</td>
            <td>{el.simNum}</td>
            <td>
              <a
                href="javascript:void(0)"
                className="text-inverse"
                title
                data-toggle="tooltip"
                data-original-title="Delete"
                onClick={() => {
                  this.removeDataFromState(el.simNum);
                }}
              >
                <i className="zmdi zmdi-close txt-danger" />
              </a>
            </td>
          </tr>
        );
      });
    }
  };

  //Ajax request to add device on server
  updateSim = (orderNo) => {
    // this.props.addDevice(this.state.data);
    // this.props.checkIMEIExistence(this.state.devices);
    let { simNumArray, location, locationName } = this.state;
    let orderId = `LIBIO${orderNo}`;
    this.props.updateSim(simNumArray, orderId, location, locationName);
  };

  //render count card
  renderCount = (orderId) => {
    const simCompany = this.state.simCompany;
    // console.log(this.props.order[this.props.match.params.id]);
    console.log(orderId);
    var uniqueModels = this.props.order[orderId]
      ? this.props.order[orderId]
      : [];
    // this.props.match && this.props.order[this.props.match.params.id]
    // ?
    // this.props.order[this.props.match.params.id]
    // this.props.order[orderId];
    // : [];

    {
      return uniqueModels.map((e) => {
        return (
          <div>
            {`${e.model}: ${simCompany.filter((x) => x === e.model).length}
            /${e.quantity}`}
          </div>
        );
      });
    }
  };

  //Remove data from state
  removeDataFromState = (simNum) => {
    // console.log(imei);
    let newData = this.state.data;
    let newSimNumArray = this.state.simNumArray;
    let newSimCompany = this.state.simCompany;

    var index = newSimNumArray.indexOf(simNum);
    // console.log(index);
    if (index !== -1) {
      newSimNumArray.splice(index, 1);
      newData.splice(index, 1);
      newSimCompany.splice(index, 1);
    }
    // console.log(newData, newDevices, newModelName);
    this.setState({
      data: newData,
      simNumArray: newSimNumArray,
      simCompany: newSimCompany,
    });
  };
  //add sims to state from barcode scanner or manual type
  addSimToState = (e) => {
    e.preventDefault();
    let simNum = e.target.value;
    let simNumArray = simNum.split("\n");
    console.log(simNumArray);
    if (this.props.formValues.values) {
      console.log(this.props.formValues.values);
    }

    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.state.typingTimeout = setTimeout(() => {
      let newData = this.state.data;
      let newSimNumArray = this.state.simNumArray;
      let newSimCompany = this.state.simCompany;
      simNumArray.forEach((simNum) => {
        simNum=simNum.slice(0, -1)
        const data = {
          ...this.props.formValues.values,
          simNum,
        };
        const simCompany = this.props.formValues.values.simCompany;
        if (
          simNum.length > 0 &&
          simNum.length <= 20 &&
          !this.state.simNumArray.includes(simNum)
        ) {
          newData.push(data);
          newSimNumArray.push(simNum);
          newSimCompany.push(simCompany);
        }
      });
      this.setState({
        data: newData,
        simNumArray: newSimNumArray,
        locationName: this.props.formValues.values.locationName,
        location: this.props.formValues.values.location,
        simCompany: newSimCompany,
      });
    }, 100);
  };

  renderFormFields = () => {
    const { handleSubmit, pristine, submitting } = this.props;
    return (
      <div className="form-wrap">
        <div className="form-horizontal">
          <div className="form-body">
            <h6 className="txt-dark capitalize-font">
              <i className="zmdi zmdi-account mr-10" />
              Device Add Info
            </h6>
            {/*for upload excel file and show them on screen}
            {/* <div>
              <input
                type="file"
                onChange={this.fileHandler.bind(this)}
                style={{ padding: "10px" }}
              />
            </div>
            <button onClick={this.showTable}>Show</button> */}
            <hr className="light-grey-hr" />
            <div className="row">
              <div
                className="col-sm-12"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "20px",
                }}
              >
                {this.renderCount(this.props.orderId)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="panel panel-default card-view">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div className="table-wrap">
                        <div className="table-responsive">
                          <table className="table table-hover display  pb-30">
                            <thead>
                              <tr>
                                <th>Order#</th>

                                <th>Location</th>
                                <th>Location Name</th>
                                <th>Sim Provider</th>
                                <th>Sim Number (SID)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Field
                                    component="select"
                                    className="form-control"
                                    name="orderId"
                                    tabIndex={1}
                                    disabled={!!this.props.order.currentOrder}
                                  >
                                    <option value="">Select Order </option>
                                    <option value="NA">Not Applicable</option>
                                    {this.props.order.currentOrder && (
                                      <option
                                        value={
                                          this.props.order.currentOrder.orderId
                                        }
                                      >
                                        {this.props.order.currentOrder.orderId}
                                      </option>
                                    )}
                                    {!this.props.order.orderNumber
                                      ? "No Order"
                                      : this.renderOptionList(
                                          this.props.order.orderNumber
                                        )}
                                  </Field>
                                </td>

                                <td>
                                  <Field
                                    component="select"
                                    className="form-control"
                                    name="location"
                                    tabIndex={1}
                                    disabled={!!this.props.order.currentOrder}
                                  >
                                    <option value="">Select Location </option>
                                    {this.renderOptionList(this.Location)}
                                  </Field>
                                </td>
                                <td>
                                  <Field
                                    component="select"
                                    className="form-control"
                                    name="locationName"
                                    disabled={!!this.props.order.currentOrder}
                                  >
                                    <option values="">
                                      Select Location Name
                                    </option>
                                    {this.props.order.currentOrder && (
                                      <option
                                        value={
                                          this.props.order.currentOrder.CompanyName.split(
                                            "/"
                                          )[1]
                                        }
                                      >
                                        {
                                          this.props.order.currentOrder.CompanyName.split(
                                            "/"
                                          )[1]
                                        }
                                      </option>
                                    )}
                                    ||
                                    {!this.props.employee.employeeName
                                      ? "No Employee"
                                      : this.renderOptionList(
                                          this.props.employee.employeeName
                                        )}
                                  </Field>
                                </td>
                                {/* <td>
                                  <Field
                                    component="select"
                                    className="form-control"
                                    name="status"
                                    tabIndex={1}
                                  >
                                    <option value="">Select Status </option>
                                    {this.renderOptionList(this.Status)}
                                  </Field>
                                </td> */}
                                <td>
                                  <Field
                                    component="select"
                                    className="form-control"
                                    name="simCompany"
                                    tabIndex={1}
                                  >
                                    <option value="">Select Sim Company</option>
                                    {this.renderOptionList(this.simCompany)}
                                  </Field>
                                </td>

                                <td>
                                  <textarea
                                    name="simNum"
                                    ref={this.myRef}
                                    className="form-control"
                                    onChange={(e) => {
                                      return this.addSimToState(e);
                                    }}
                                    placeholder="eg.8600020132565"
                                    disabled={
                                      !(
                                        this.props.formValues &&
                                        this.props.formValues.values.location &&
                                        this.props.formValues.values
                                          .locationName
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="seprator-block" />
          <h6 className="txt-dark capitalize-font">
            <i className="zmdi zmdi-account-box mr-10" />
            Device List
          </h6>
          <hr className="light-grey-hr" />
          <div className="row">
            <div className="col-sm-12">
              <div className="panel panel-default card-view">
                <div className="panel-wrapper collapse in">
                  <div className="panel-body">
                    <div className="table-wrap">
                      <div className="table-responsive">
                        <table className="table table-hover display  pb-30">
                          <thead>
                            <tr>
                              <th>Order#</th>

                              <th>Location</th>
                              <th>Location Name</th>
                              <th>Sim Provider</th>

                              <th>Sim Number</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>{this.renderDeviceList()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-actions mt-10">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-offset-3 col-md-9">
                    <button
                      type="button"
                      className="btn btn-success  mr-10"
                      onClick={() => this.updateSim(this.props.orderId)}
                    >
                      Submit
                    </button>
                    <button type="button" className="btn btn-default">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6"> </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div id="profile_15" className="tab-pane fade" role="tabpanel">
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default card-view">
              <div className="panel-heading">
                <div className="pull-left">
                  <h6 className="panel-title txt-dark">Add Sim Details</h6>
                </div>
                <div className="clearfix" />
              </div>
              <div className="panel-wrapper collapse in">
                <div className="panel-body">
                  <div className="row">
                    <div className="col-md-12">{this.renderFormFields()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProp = (state) => {
  return {
    employee: state.employee,
    order: state.order,
    client: state.client,
    deviceModel: state.device,
    formValues: state.form.SimUpdateForm,
    initialValues: {
      orderId:
        (state.order.currentOrder && state.order.currentOrder.orderId) || "",
      location: (state.order.currentOrder && "Dealer") || "",
      status: "New",
      locationName:
        (state.order.currentOrder &&
          state.order.currentOrder.CompanyName.split("/")[1]) ||
        "",
    },
  };
};

//Client Side Form validation
const validate = (formValues) => {
  const errors = {};
  // var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!formValues.username) {
    errors.username = "You must enter your username";
  } else if (formValues.username.length < 5) {
    errors.username = "Employee Id must be atleast 7 long";
  }
  if (!formValues.pswd) {
    errors.pswd = "You must enter the password";
  } else if (formValues.pswd.length < 6) {
    errors.pswd = "Password must be atleast 6 long charector";
  }
  return errors;
};

//Connect with redux form with local redux store
export default connect(mapStateToProp, {
  getAllEmployeeName,
  fetchAllOrderNumber,
  fetchOrderByCdid,
  //   checkIMEIExistence,
  updateSim,
  fetchOrderItemByCdid,
})(
  reduxForm({
    form: "SimUpdateForm",
    enableReinitialize: true,
    validate,
  })(UpdateSim)
);
