import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        {/* Left Sidebar Menu */}
        <div className="fixed-sidebar-left">
          <ul className="nav navbar-nav side-nav nicescroll-bar">
            <li className="navigation-header">
              <span>Main</span>
              <i className="zmdi zmdi-more" />
            </li>
            <li>
              <Link
                className="active"
                  
                data-toggle="collapse"
                data-target="#dashboard_dr"
                to="/dashboard"
                onClick={() => {
                  setTimeout(() => {
                    window.location.reload(true);
                  }, 50);
                }}
              >
                <div className="pull-left">
                  <i className="fa fa-gear mr-20" />
                  <span className="right-nav-text">Dashboard</span>
                </div>

                <div className="clearfix" />
              </Link>
            </li>
            <li>
              <a
                  
                data-toggle="collapse"
                data-target="#email_dr"
              >
                <div className="pull-left">
                  <i className="fa fa-qrcode mr-20" />
                  <span className="right-nav-text">Inventory</span>
                </div>

                <div className="pull-right">
                  <i className="zmdi zmdi-caret-down" />
                </div>
                <div className="clearfix" />
              </a>
              <ul id="email_dr" className="collapse collapse-level-2">
                <li>
                  <Link to="/inventory/add">Add Inventory</Link>
                </li>
                <li>
                  <Link to="/devices/update">Update Devices</Link>
                </li>
                <li>
                  <Link to="/devices">Device List</Link>
                </li>
                <li>
                  <Link to="/sims">Sim List</Link>
                </li>
              </ul>
            </li>
            <li>
              <a
                  
                data-toggle="collapse"
                data-target="#lead_dr"
              >
                <div className="pull-left">
                  <i className="fa fa-hourglass-o mr-20" />
                  <span className="right-nav-text">Leads</span>
                </div>

                <div className="pull-right">
                  <i className="zmdi zmdi-caret-down" />
                </div>
                <div className="clearfix" />
              </a>
              <ul id="lead_dr" className="collapse collapse-level-2">
                <li>
                  <Link to="/leads/add">New Lead</Link>
                </li>
                <li>
                  <Link to="/leads">Lead List</Link>
                </li>
              </ul>
            </li>
            <li>
              <a
                  
                data-toggle="collapse"
                data-target="#client_dr"
              >
                <div className="pull-left">
                  <i className="fa fa-users mr-20" />
                  <span className="right-nav-text">Clients</span>
                </div>

                <div className="pull-right">
                  <i className="zmdi zmdi-caret-down" />
                </div>
                <div className="clearfix" />
              </a>
              <ul id="client_dr" className="collapse collapse-level-2">
                <li>
                  <Link to="/clients/add">Add Client</Link>
                </li>
                <li>
                  <Link to="/clients">Client list</Link>
                </li>
              </ul>
            </li>
            <li>
              <a
                  
                data-toggle="collapse"
                data-target="#employee_dr"
              >
                <div className="pull-left">
                  <i className="fa fa-male mr-20" />
                  <span className="right-nav-text">Employees</span>
                </div>

                <div className="pull-right">
                  <i className="zmdi zmdi-caret-down" />
                </div>
                <div className="clearfix" />
              </a>
              <ul id="employee_dr" className="collapse collapse-level-2">
                <li>
                  <Link to="/employees/add">Add Employee</Link>
                </li>
                <li>
                  <Link to="/employees">Employee list</Link>
                </li>
              </ul>
            </li>

            <li>
              <a
                  
                data-toggle="collapse"
                data-target="#vendor_dr"
              >
                <div className="pull-left">
                  <i className="fa fa-sitemap mr-20" />
                  <span className="right-nav-text">Vendors</span>
                </div>

                <div className="pull-right">
                  <i className="zmdi zmdi-caret-down" />
                </div>
                <div className="clearfix" />
              </a>
              <ul id="vendor_dr" className="collapse collapse-level-2">
                <li>
                  <Link to="/vendors/add">Add Vendor</Link>
                </li>
                <li>
                  <Link to="/vendors">Vendor list</Link>
                </li>
              </ul>
            </li>
            <li>
              <a
                  
                data-toggle="collapse"
                data-target="#order_dr"
              >
                <div className="pull-left">
                  <i className="fa fa-shopping-cart mr-20" />
                  <span className="right-nav-text">Orders</span>
                </div>

                <div className="pull-right">
                  <i className="zmdi zmdi-caret-down" />
                </div>
                <div className="clearfix" />
              </a>
              <ul id="order_dr" className="collapse collapse-level-2">
                <li>
                  <Link to="/orders/add">Add Order</Link>
                </li>
                <li>
                  <Link to="/orders">Order list</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        {/* /Left Sidebar Menu */}
      </div>
    );
  }
}
