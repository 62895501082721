import React, { Component } from "react";

import {
  fetchUser,
  fetchClientByClid,
  updateClient,
} from "../../actions/index";
import { connect } from "react-redux";
import ClientForm from "./ClientForm";

class ClientEdit extends Component {
  state = { dataReceive: null };
  async componentDidMount() {
    console.log(this.props.match.params.id);
    await this.props.fetchClientByClid(
      this.props.match.params.id
    );
  }
  //form submit click handler
  onFormSubmitEdit = (formValues) => {
    this.props.updateClient(formValues, this.props.match.params.id);
  };

  render() {
    let data = "";
    if (this.props.client[this.props.match.params.id]) {
      data = this.props.client[this.props.match.params.id];
    }
    return !data ? null : (
      <div>
        {this.props.client ? (
          <ClientForm
            onSubmit={this.onFormSubmitEdit}
            mode="Edit"
            initialValues={{
              companyName: data.CompanyName,
              accMgr: data.AccountManager,
              address: data.Address,
              city: data.City,
              gst: data.GST,
              email: data.Email,
              number: data.Mobile,
              clientType: data.ClientRole,
              contactPerson: data.ContactPerson,
              country: data.Country,
              pinCode: data.PinCode,
              salesPerson: data.SalesPerson,
              state: data.State,
            }}
          />
        ) : (
          "No Edit"
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { client: state.clients };
};

export default connect(mapStateToProps, {
  fetchUser,
  fetchClientByClid,
  updateClient,
})(ClientEdit);
