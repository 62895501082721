import * as TYPES from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.FETCH_ALL_EMPLOYEE:
      return {
        ...state,
        employee: action.payload.employeeList,
        report: action.payload.report,
      };
    case TYPES.FETCH_EMPLOYEE_BY_SEARCH:
      return {
        ...state,
        employee: action.payload.employeeList,
      };
    case TYPES.FETCH_ALL_EMPLOYEE_NAME:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        employeeName: action.payload.list,
      };
    case TYPES.FETCH_NOTIFICATION:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        notification: action.payload.notifications,
      };
    default:
      return state;
  }
};
