import React, { Component } from "react";
import { fetchUser, addClient } from "../../actions/index";
import { connect } from "react-redux";
import ClientForm from "./ClientForm";

class AddClient extends Component {
  //form submit click handler
  onFormSubmitAdd = (formValues) => {
    this.props.addClient(formValues);
  };

  renderSuccessMessage = () => {
    if (this.props.client) {
      return (
        <div className="jq-toast-wrap bottom-left">
          <div
            className="jq-toast-single jq-has-icon jq-icon-success"
            style={{ textAlign: "left", display: "block" }}
          >
            <span
              className="jq-toast-loader jq-toast-loaded"
              style={{
                WebkitTransition: "width 3.1s ease-in",
                OTransition: "width 3.1s ease-in",
                transition: "width 3.1s ease-in",
                backgroundColor: "#f8b32d",
              }}
            />
            <span className="close-jq-toast-single">×</span>
            <h2 className="jq-toast-heading">Client added successfully</h2>Use
            the predefined ones, or specify a custom position object.
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <div>
        <ClientForm mode="Add" onSubmit={this.onFormSubmitAdd} />
        {this.renderSuccessMessage()}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return { client: state.clients.client };
};

export default connect(mapStateToProps, {
  fetchUser,
  addClient,
})(AddClient);
