import React, { Component } from "react";

export default class AlertPopup extends Component {
  render() {
    return (
      <div className="wrapper theme-1-active pimary-color-green">
        Main Content
        <div classname="page-wrapper">
          <div classname="container-fluid">
            Title
            <div classname="row heading-bg">
              <div classname="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h5 classname="txt-dark">sweet alert</h5>
              </div>
              Breadcrumb
              <div classname="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <ol classname="breadcrumb">
                  <li>
                    <a href="index-2.html">Dashboard</a>
                  </li>
                  <li>
                    <a href="fakeurl">
                      <span>ui-elements</span>
                    </a>
                  </li>
                  <li classname="active">
                    <span>sweetalert</span>
                  </li>
                </ol>
              </div>
              Breadcrumb
            </div>
            Title Row
            <div classname="row">
              <div classname="col-lg-3 col-md-6 col-xs-12">
                <div classname="panel panel-default card-view">
                  <div classname="panel-heading">
                    <div classname="pull-left">
                      <h6 classname="panel-title txt-dark">A basic message</h6>
                    </div>
                    <div classname="clearfix"></div>
                    <div classname="panel-wrapper collapse in">
                      <div classname="panel-body">
                        <img
                          src="../img/sweetalert/alert.png"
                          alt="alert"
                          classname="img-responsive model_img"
                          id="sa-basic"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div classname="col-lg-3 col-md-6 col-xs-12">
                  <div classname="panel panel-default card-view">
                    <div classname="panel-heading">
                      <div classname="pull-left">
                        <h6 classname="panel-title txt-dark">
                          title with a text under
                        </h6>
                      </div>
                      <div classname="clearfix"></div>
                      <div classname="panel-wrapper collapse in">
                        <div classname="panel-body">
                          <img
                            src="../img/sweetalert/alert2.png"
                            alt="alert"
                            classname="img-responsive model_img"
                            id="sa-title"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div classname="col-lg-3 col-md-6 col-xs-12">
                    <div classname="panel panel-default card-view">
                      <div classname="panel-heading">
                        <div classname="pull-left">
                          <h6 classname="panel-title txt-dark">
                            success message
                          </h6>
                        </div>
                        <div classname="clearfix"></div>
                        <div classname="panel-wrapper collapse in">
                          <div classname="panel-body">
                            <img
                              src="../img/sweetalert/alert3.png"
                              alt="alert"
                              classname="img-responsive model_img"
                              id="sa-success"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div classname="col-lg-3 col-md-6 col-xs-12">
                      <div classname="panel panel-default card-view">
                        <div classname="panel-heading">
                          <div classname="pull-left">
                            <h6 classname="panel-title txt-dark">
                              warning message
                            </h6>
                          </div>
                          <div classname="clearfix"></div>
                          <div classname="panel-wrapper collapse in">
                            <div classname="panel-body">
                              <img
                                src="../img/sweetalert/alert4.png"
                                alt="alert"
                                classname="img-responsive model_img"
                                id="sa-warning"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    Row Row
                    <div classname="row">
                      <div classname="col-lg-4 col-md-4 col-xs-12">
                        <div classname="panel panel-default card-view">
                          <div classname="panel-heading">
                            <div classname="pull-left">
                              <h6 classname="panel-title txt-dark">
                                a basic message with option
                              </h6>
                            </div>
                            <div classname="clearfix"></div>
                            <div classname="panel-wrapper collapse in">
                              <div classname="panel-body">
                                <img
                                  src="../img/sweetalert/alert5.png"
                                  alt="alert"
                                  classname="img-responsive model_img"
                                  id="sa-params"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div classname="col-lg-4 col-md-4 col-xs-12">
                          <div classname="panel panel-default card-view">
                            <div classname="panel-heading">
                              <div classname="pull-left">
                                <h6 classname="panel-title txt-dark">
                                  Alert with Image
                                </h6>
                              </div>
                              <div classname="clearfix"></div>
                              <div classname="panel-wrapper collapse in">
                                <div classname="panel-body">
                                  <img
                                    src="../img/sweetalert/alert7.png"
                                    alt="alert"
                                    classname="img-responsive model_img"
                                    id="sa-image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div classname="col-lg-4 col-md-4 col-xs-12">
                            <div classname="panel panel-default card-view">
                              <div classname="panel-heading">
                                <div classname="pull-left">
                                  <h6 classname="panel-title txt-dark">
                                    Alert with time
                                  </h6>
                                </div>
                                <div classname="clearfix"></div>
                                <div classname="panel-wrapper collapse in">
                                  <div classname="panel-body">
                                    <img
                                      src="../img/sweetalert/alert6.png"
                                      alt="alert"
                                      classname="img-responsive model_img"
                                      id="sa-close"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          Row
                        </div>
                        Footer
                        <footer classname="footer container-fluid pl-30 pr-30">
                          <div classname="row">
                            <div classname="col-sm-12">
                              <p>
                                2017 © Libicon. Pampered by Libi Technologies
                              </p>
                            </div>
                          </div>
                        </footer>
                        Footer
                      </div>
                      Main Content
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
