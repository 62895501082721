import * as TYPES from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.FETCH_ALL_DEVICE:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        devices: action.payload,
        totalDevices: action.payload.totalDevices,
      };
    case TYPES.FETCH_DEVICE_BY_SEARCH:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        devices: action.payload,
      };
    case TYPES.FETCH_ALL_DEVICE_MODEL_NAME:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        deviceModel: action.payload.devices.Device,
      };
    default:
      return state;
  }
};
